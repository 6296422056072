import styled from "styled-components";

export const CenteredPageTitleStyles = styled.div`
  .title-h2 {
    text-transform: uppercase;
    color: #0c85e9;
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    font-size: 35px;
  }
`;
