import { createSlice } from "@reduxjs/toolkit";

import StorageService from "../../services/StorageService";

const Storage = new StorageService();

const initialState = {
  user: Storage.getUser(),
  token: Storage.getToken(),
  loading: false,
  error: null,
  broker_data: {
    loading: true,
    broker: null,
    documents: [],
    reports: [],
    bills: [],
    error: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    loginSuccess: (state, { payload: { user, token } }) => {
      state.loading = false;
      state.user = user;
      state.token = token;
    },
    brokerDataPending: (state) => {
      state.broker_data.loading = true;
      state.broker_data.error = null;
    },
    brokerDataRejected: (state, { payload }) => {
      state.broker_data.loading = false;
      state.broker_data.error = payload;
    },
    brokerDataSuccess: (state, { payload }) => {
      state.broker_data = { ...initialState.broker_data, ...payload };
    },
  },
});
