import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import StorageService from "../services/StorageService";

const Storage = new StorageService();

const useAuth = () => {
  const authUser = useSelector((store) => store.user);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isUserSectionAB, setIsUserSectionAB] = useState(false);
  const [hasBackoffice, setHasBackoffice] = useState(false)

  useEffect(() => {
    if (authUser.token) {
      setIsUserLoggedIn(true);
      setIsUserSectionAB(checkIfAB(authUser));
      setHasBackoffice(checkHasBackoffice(authUser))
      return;
    }

    let token = Storage.getToken();

    if (token !== null) {
      setIsUserLoggedIn(true);
      setIsUserSectionAB(checkIfAB(authUser));

      return;
    }

    setIsUserLoggedIn(false);
    setIsUserSectionAB(false);
    setHasBackoffice(false)
  }, [authUser.token]);

  const checkHasBackoffice = (authUser) => {
    if (authUser.user.backoffice === 1) return true
    return false
  }

  return {
    isUserLoggedIn,
    isUserSectionAB,
    hasBackoffice
  };
};

const checkIfAB = (authUser) => {
  if (
    authUser.user.broker_section === "A" ||
    authUser.user.broker_section === "B"
  )
    return true;
  return false;
};


export default useAuth;
