import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { generalAlertError } from '../../common/utils/alerts'
import { PurchasedQuotationsStyles } from './PurchasedQuotations.styles'
import { getPurchasedContracts, resetContractsEmmese } from '../../features/contracts/contractsActions'

const PurchasedContracts = () => {
    const state = useSelector(store => store.contracts.contracts);

    const { loading, contracts } = state;

    const [search, setSearch] = useState({
        search: '',
        plate: '',
        date_from: '',
        date_to: ''
    })

    const searchHandler = (e) => {
        e.preventDefault();

        if (search.date_from !== '' && search.date_to === '') {
            return generalAlertError('Devi selezionare anche data al')
        }

        if (search.date_from === '' && search.date_to !== '') {
            return generalAlertError('Devi selezionare anche data da')
        }

        getPurchasedContracts(search);
    }

    const handleChange = ({ target: { value, name } }) => {
        setSearch({ ...search, [name]: value })
    }

    useEffect(() => {
        getPurchasedContracts();
    }, []);


    return (
        <PurchasedQuotationsStyles>
            <h5 className='mb-4'>Lista Contratti Aquistati</h5>
            <div className="d-flex flex-md-row flex-column-reverse">
                <div className="col-xl-8 col-md-7 col-lg-8">
                    <div className="mt-3">
                        {loading ?
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <div className="spinner-border" role="status"></div>
                                <span>Attendere prego...</span>
                            </div>
                            :
                            <ul className="list-group">
                                {contracts.length > 0 ?
                                    contracts.map((contract, index) => (
                                        <li key={index} className="list-group-item d-flex flex-xl-row flex-column justify-content-between align-items-center">
                                            <div className="ms-2 me-auto mb-2">
                                                <div className="fw-bold">
                                                    Cliente: {contract.client.gender !== 'G' ? contract.client.name + ' ' + contract.client.surname : contract.client.business_name}
                                                </div>
                                                Product: {contract.product.name}
                                                <br />
                                                Targa: {contract.contract_request
                                                    .request_vehicle.vehicle_plate}
                                                <br />
                                                <small>
                                                    <strong>Decorrenza: {new Date(contract.contract_request.start_date).toLocaleDateString('it-IT', { 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' })}</strong>
                                                </small>
                                                <br />
                                                <small>
                                                    <strong>Scadenza: {new Date(contract.contract_request.end_date).toLocaleDateString('it-IT', { 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' })}</strong>
                                                </small>
                                            </div>

                                            <div className="mx-5 my-3">
                                                <span className="badge text-bg-dark">
                                                    {contract.status === 7 && 'Emmesa'}
                                                </span>
                                            </div>

                                            <Link to={`/contract/purchase/${contract.id}`}>
                                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                                    Vai al preventivo
                                                </span>
                                            </Link>
                                        </li>
                                    ))
                                    :
                                    <li className="list-group-item d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">
                                                Nessuna contratto trovato!
                                            </div>
                                        </div>
                                    </li>}
                            </ul>
                        }
                    </div>

                </div>
                <div className="col-lg-3 col-md-5 col-xl-3 p-3 quotation-filters">
                    <form onSubmit={searchHandler} className='d-flex align-items-end justify-content-center flex-column'>
                        <input type="text" name='search' className='search-input form-control mb-2' value={search.search} onChange={handleChange} placeholder='Ricerca contratti aquistati...' />
                        <input type="text" name='plate' className='search-plate form-control mb-2' value={search.plate} onChange={handleChange} placeholder='Ricerca targa...' />
                        <div className='w-100'>
                            <label htmlFor="date_from">Data creazione da</label>
                            <input type="date" name='date_from' onChange={handleChange} value={search.date_from} className='date-input form-control mb-2' placeholder='Data creazione da' />
                        </div>
                        <div className='w-100'>
                            <label htmlFor="date_to">Data creazione al</label>
                            <input type="date" name='date_to' className='date-input form-control mb-0' onChange={handleChange} value={search.date_to} min={search.date_from} placeholder='Data creazione al' />
                        </div>
                        <button type="submit" className="search-btn mt-2">
                            Cerca
                        </button>
                    </form>
                </div>
            </div>
        </PurchasedQuotationsStyles>
    )
}

export default PurchasedContracts
