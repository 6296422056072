import React from 'react'
import { InfoBoxContainer, InfoItem, RequestCardInfoStyles, TitleActionBox } from './RequestCardInfo.styles'
import { ModificaDatiButton } from './Redesign.styles'
import editIcon from '../../assets/redesign_icons/edit-icon.svg'
import moment from 'moment'

export const RequestCardInfo = ({ contractRequest, modificaDati }) => {

  return (
    <RequestCardInfoStyles>
      <TitleActionBox>
        <h4>
          Informazioni
        </h4>
        <ModificaDatiButton onClick={modificaDati}>
          <p>Modifica Dati</p>
          <img src={editIcon} alt="Modifica Dati" />
        </ModificaDatiButton>
      </TitleActionBox>
      <InfoBoxContainer>
        <InfoItem>
          <h4>Contraente:</h4>
          <p>
            {contractRequest.request_client.gender !== 'G' ? contractRequest.request_client.name + ' ' + contractRequest.request_client.surname : contractRequest.request_client.business_name}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Marca:</h4>
          <p>{contractRequest.request_vehicle.brand.name}</p>
        </InfoItem>
        <InfoItem>
          <h4>Modello:</h4>
          <p>{contractRequest.request_vehicle.model.name}</p>
        </InfoItem>
        <InfoItem>
          <h4>Residenca:</h4>
          <p>{contractRequest.request_client.residence.name + ', ' + contractRequest.request_client.residence.communal_territory.car_plate_symbol}</p>
        </InfoItem>
        <InfoItem>
          <h4>Targa:</h4>
          <p>{contractRequest.request_vehicle.vehicle_plate}</p>
        </InfoItem>
        <InfoItem>
          <h4>Inizio Copertura:</h4>
          <p>{moment(contractRequest.start_date).format('DD-MM-YYYY')}</p>
        </InfoItem>
      </InfoBoxContainer>
    </RequestCardInfoStyles>
  )
}