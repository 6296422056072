import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contracts: {
    loading: true,
    error: null,
    contracts: [],
  },
};

export const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    listPurchasedContractsPending: (state) => {
      state.contracts.loading = true;
      state.contracts.error = null;
    },
    listPurchasedContractsRejected: (state, { payload }) => {
      state.contracts.loading = false;
      state.contracts.error = payload;
    },
    listPurchasedContractsSuccess: (state, { payload }) => {
      state.contracts.loading = false;
      state.contracts.contracts = payload;
    },
    listContractsResetEmmese: (state) => {
      state.contracts = { ...initialState.contracts };
    },
  },
});
