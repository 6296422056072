import styled from "styled-components";

export const ContractCardStyles = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 20px 40px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  height: 100%;
  gap: 50px;

  @media only screen and (max-width: 1200px) {
    padding: 15px 20px;
    flex-direction: column;
  }
`;

export const LeftCol = styled.div`
  width: 75%;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;
export const RightCol = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const LogoPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;

  @media only screen and (max-width: 1200px) {
    gap: 10px;
  }
`;
export const LogoContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #fbfbf9;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100px;
  }

  @media only screen and (max-width: 1200px) {
    width: 90px;
    height: 90px;
    img {
      max-width: 60px;
    }
  }
`;
export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`;

export const Price = styled.div`
  h5 {
    margin-bottom: -5px;
    color: #0c85e9;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  h6 {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 2px;
      width: 45%;
      left: -2px;
      top: calc(50% - 2px);
      background-color: #343434;
      z-index: 9999;
    }
  }

  h2 {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media only screen and (max-width: 900px) {
    h5 {
      font-size: 14px;
    }

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const QuoteInformationContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;

  @media only screen and (max-width: 1200px) {
    gap: 15px;
    margin-top: 15px;
    flex-wrap: wrap;
  }
`;

export const QuoteInformationItem = styled.div`
  h6 {
    margin-bottom: 0;
    color: #0c85e9;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media only screen and (max-width: 900px) {
    h6 {
      font-size: 12px;
    }

    p {
      font-size: 14px;
    }
    width: 45%;
  }
`;

export const AquistaButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 10px;
  height: 200px;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    flex-direction: row;
    width: 100%;
    margin-top: 50px;
    height: auto;
  }
`;

export const AquistaButton = styled.button`
  border-radius: 15px;
  background: #0c85e9;
  border: 1px solid #0c85e9;
  padding: 12px 40px;
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  transition: all 300ms ease-in-out;

  &:hover {
    background: transparent;
    border: 1px solid #0c85e9;
    color: #0c85e9;
  }

  @media only screen and (max-width: 900px) {
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 10px;
  }
`;

export const SelectedGuaranteesContainer = styled.div`
  height: 100%;
  width: 100%;
  h2 {
    margin-bottom: 0;
    color: var(--common-black, #1a1921);
    text-align: right;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }

  @media only screen and (max-width: 900px) {
    margin-top: 15px;
    height: auto;
    h2 {
      font-size: 18px;
      text-align: start;
    }
  }
`;

export const GuaranteesList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 5px;
  margin-top: auto;
  height: 100%;
`;

export const GuaranteesListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  h6 {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;

    &::before {
      content: "";
      display: inline-block;
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #0c85e9;
    }
  }

  p {
    color: #0c85e9;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 900px) {
    h6 {
      font-size: 14px;

      &::before {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #0c85e9;
      }
    }

    p {
      font-size: 16px;
    }
  }
`;

export const MarkupContainer = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  width: 100%;
  margin-top: 30px;
  padding: 20px;
`;

export const MarkupTitle = styled.h2`
  color: #343434;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;

  @media only screen and (max-width: 900px) {
    font-size: 18px;
  }
`;

export const AddGuaranteesButton = styled.button`
  border-radius: 15px;
  background: #d1e9fd;
  border: 1px solid #d1e9fd;
  padding: 12px 40px;
  color: #0c85e9;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: auto;
  display: table;
  transition: all 300ms ease-in-out;

  &:hover {
    background: transparent;
    border: 1px solid #0c85e9;
    color: #0c85e9;
  }

  @media only screen and (max-width: 900px) {
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 10px;
    width: 100%;
  }
`;

export const MarkupSliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  gap: 20px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const MarkupPrice = styled.div`
  border-radius: 10px;
  min-width: 90px;
  text-align: center;
  padding: 10px;
  background: #d1e9fd;
  color: #343434;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const MarkupSlider = styled.div`
  width: 100%;

  @media screen and (max-width: 900px) {
    .range-responsive-circle {
      width: 30px !important;
      height: 30px !important;
    }

    .range-responsive-slide {
      height: 20px !important;
    }

    .range-responsive-text {
      font-size: 16px !important;
    }
  }
`;
