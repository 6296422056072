import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  form_utilities: {
    loading: true,
    isLoadingVehicleModels: false,
    isSubmitting: false,
    states: [],
    communes: [],
    vehicleMakes: [],
    vehicleModels: [],
    formData: null,
    error: null,
    index: 1,
    searchResults: [],
    selectedClient: null,
    selectedClientOldData: null,
    selectedClientId: null,
  },
  list_contracts: {
    loading: true,
    error: null,
    contractRequest: null,
    markupLoading: false,
  },
  not_saved_requests: {
    loading: true,
    error: null,
    contractRequestsNotSaved: [],
  },
};

export const utilitiesSlice = createSlice({
  name: "utilities",
  initialState,
  reducers: {
    updateFormData: (state, { payload }) => {
      state.form_utilities = { ...state.form_utilities, ...payload };
    },
    resetInitialFormData: (state) => {
      state.form_utilities = { ...initialState.form_utilities };
    },
    updateFormIndex: (state, { payload }) => {
      state.form_utilities = { ...state.form_utilities, index: payload };
    },

    listContractsPending: (state) => {
      state.list_contracts.loading = true;
      state.list_contracts.error = null;
      state.list_contracts.contractRequest = null;
    },
    listContractsRejected: (state, { payload }) => {
      state.list_contracts.loading = false;
      state.list_contracts.error = payload;
    },
    listContractsFulfilled: (state, { payload }) => {
      state.list_contracts.loading = false;
      state.list_contracts.contractRequest = payload;
    },
    listContractChangeLoading: (state, { payload }) => {
      state.list_contracts.markupLoading = payload;
    },
    listContractsReset: (state) => {
      state.list_contracts = { ...initialState.list_contracts };
    },
    listNotSavedContractRequestsPending: (state) => {
      state.not_saved_requests.loading = true;
      state.not_saved_requests.error = null;
    },
    listNotSavedContractRequestsReject: (state, { payload }) => {
      state.not_saved_requests.loading = false;
      state.not_saved_requests.error = payload;
    },
    listNotSavedContractRequestsSuccess: (state, { payload }) => {
      state.not_saved_requests.loading = false;
      state.not_saved_requests.contractRequestsNotSaved = payload;
    },
  },
});
