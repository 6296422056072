import styled from "styled-components";

export const PageLoadingStyles = styled.div`
  .content-loading {
    align-items: center;
    justify-content: center;
    min-height: 600px;
    z-index: 9000;
  }
`;

export const PageKeepLoaderStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;
