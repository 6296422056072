import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import ProgressBar from '../../common/components/ProgressBar';
import CenteredPageTitle from '../../common/components/CenteredPageTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import generateFiscalCode from '../../common/helpers/generateFiscalCode'
import { genders } from '../../common/constants';
import { FieldsBySteps, FormValidation } from './RequestFormFlow.validation'
import { FormInputText } from '../../common/formInputs/FormInputText'
import { FormInputRadio } from '../../common/formInputs/FormInputRadio'
import { FormInputSelect } from '../../common/formInputs/FormInputSelect'
import { FormInputDate } from '../../common/formInputs/FormInputDate'
import { FormInputAddress } from '../../common/formInputs/FormInputAddress'
import getAllowedPolicyEffectDate from '../../common/helpers/getAllowedPolicyEffectDate'
import { useSelector } from 'react-redux'
import PageLoading from '../../common/components/elements/PageLoading'
import { formIndexChange, getInitialFormData, requestGetClientData, requestGetVehicleModels, requestSearchClient, requestSubmitAnswers, requestUpdateFormData, resetInitialFormData } from '../../features/contractRequests/contractRequestsActions';
import FormReactSelectBrand from '../../common/formInputs/FormReactSelectBrand'
import FormReactSelectModel from '../../common/formInputs/FormReactSelectModel'
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace'
import FormReactSelectResidenceInput from '../../common/formInputs/FormReactSelectResidenceInput'
import { modifyRequestData, updateActiveCustomer } from '../../services/axios-client/axeCommons';
import { useDebouncedEffect } from '../../common/utils/useDebouncedEffect';
import { FormInputCheckbox } from '../../common/formInputs/FormInputCheckbox';
import ActiveClientModal from './ActiveClientModal';

const steps = [
  "Dati del Veicolo",
  "Dati Personali",
];

const RequestFormFlow = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const skipCheckGenderRef = useRef(false);
  const {
    loading, states, communes, vehicleMakes,
    isLoadingVehicleModels, vehicleModels, index,
    formData, isSubmitting, searchResults, selectedClient,
    selectedClientOldData, selectedClientId,
  } = useSelector(store => store.utilities.form_utilities)
  const [searchClient, setSearchClient] = useState("");

  const { register, formState: { errors, touchedFields }, watch, setValue, trigger } = useForm({
    mode: 'all',
    resolver: yupResolver(FormValidation),
    shouldFocusError: true,
    defaultValues: {
      name: "", //OK
      surname: "", //OK
      date_of_birth: "", //OK
      birth_commune_id: "", //OK
      gender: "", //OK
      phone: "",//OK
      email: "",//OK
      residence_commune_id: null, //OK
      born_abroad: false, //OK
      birth_state_code: '1', //OK
      address: "", //OK
      house_number: '', //OK
      postal_code: "", //OK
      fiscal_code: "", //OK
      existing_customer: false, //OK
      client_id: null, //OK
      start_date: "", //OK
      business_name: '', //OK
      vat_number: '', //OK
      company_type: null, //OK
      vehicle_plate: '', //OK
      vehicle_brand_code: '', //OK
      vehicle_model_code: '', //OK
    }
  })

  const updateUserData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const userData = watch()

  const getFiscalCode = (birthDate) => {
    let d = birthDate.split("-");
    let fData = {
      name: userData.name,
      surname: userData.surname,
      gender: userData.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };
    if (userData.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.id === userData.birth_state_code
      )[0].name;
      fData.birthplaceProvincia = "EE";
    } else {
      const c = communes.find(
        (municipality) =>
          municipality.id === userData.birth_commune_id
      );
      fData.birthplace = c.name;
      fData.birthplaceProvincia = c.communal_territory.car_plate_symbol;
    }
    return generateFiscalCode(fData);
  };

  const nextButton = async () => {
    let res = await trigger(FieldsBySteps[index - 1], { shouldFocus: true })

    if (res !== true) {
      return;
    }

    if (index === 2) {
      submitAnswers();
      return;
    }

    formIndexChange(index + 1);
    setTimeout(() => window.scrollTo(0, 0), 500);
  };

  const submitAnswers = () => {
    let data = {
        ...userData
    };

    data.fiscal_code = (userData.gender !== 'G') ? getFiscalCode(userData.date_of_birth) : null;

    requestSubmitAnswers(data, navigate);
};

  const getVehicleModels = async () => {
    requestGetVehicleModels(userData.vehicle_brand_code);
  };

  const prevButton = () => {
    if (index > 1) {
      formIndexChange(index - 1)
    }
  };

  const onSearchChange = (value) => {
    setSearchClient(value);

    if (value.length === 0) {
      requestUpdateFormData({
        searchResults: [],
      });
      return;
    }
  };

  const closeClientModal = () => {
    setSearchClient("");
    requestUpdateFormData({
        selectedClient: null,
        selectedClientOldData: null,
        selectedClientId: null,
        searchResults: [],
    });
  };

  const getCustomerData = async (clientId) => {
    requestGetClientData(clientId);
  };

  const searchActiveClient = (searchClient) => () => {
    if (searchClient.length === 0) return;
    requestSearchClient(searchClient);
  };

  const confirmActiveClient = async (selectedClient) => {
    if (
        JSON.stringify(selectedClientOldData) !== JSON.stringify(selectedClient)
    ) {
        await updateActiveCustomer(selectedClientId, {
            ...selectedClient,
        });
    }

    skipCheckGenderRef.current = true;

    if (selectedClient.gender === 'G') {
        updateUserData({
            born_abroad: false,
            fiscal_code: null,
            gender: selectedClient.gender,
            name: null,
            phone: selectedClient.phone,
            postal_code: selectedClient.postal_code,
            surname: null,
            address: selectedClient.address,
            house_number: selectedClient.house_number,
            date_of_birth: null,
            email: selectedClient.email,
            residence_commune_id: selectedClient.residence_commune_id,
            birth_state_code: null,
            birth_commune_id: null,
            business_name: selectedClient.business_name,
            vat_number: selectedClient.vat_number,
            company_type: selectedClient.company_type,
            client_id: selectedClientId,
        });
    } else {
        updateUserData({
            born_abroad:
                selectedClient.born_abroad !== null
                    ? selectedClient.born_abroad
                    : false,
            fiscal_code: selectedClient.fiscal_code,
            gender: selectedClient.gender,
            name: selectedClient.name,
            phone: selectedClient.phone,
            postal_code: selectedClient.postal_code,
            surname: selectedClient.surname,
            address: selectedClient.address,
            house_number: selectedClient.house_number,
            date_of_birth: selectedClient.date_of_birth,
            email: selectedClient.email,
            birth_commune_id: selectedClient.birth_commune_id,
            residence_commune_id: selectedClient.residence_commune_id,
            birth_state_code: selectedClient.birth_state_code,
            business_name: null,
            vat_number: null,
            company_type: null,
            client_id: selectedClientId,
        });
    }

    setSearchClient("");
    requestUpdateFormData({
        selectedClient: null,
        selectedClientOldData: null,
        selectedClientId: null,
        searchResults: [],
    });
  };

  useDebouncedEffect(searchActiveClient(searchClient), [searchClient], 400);

  useEffect(() => {
    getInitialFormData();

    return () => {
      resetInitialFormData();
    }
  }, [])

  useEffect(() => {
    if (userData.vehicle_brand_code) {
        getVehicleModels()
    }
  }, [userData.vehicle_brand_code])

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
        skipCheckGenderRef.current = false;
        return;
    }

    const triggerValidation = {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
    };

    if (userData.gender === 'G') {
        setValue('date_of_birth', null);
        setValue('birth_state_code', null);
        setValue('birth_commune_id', null);
        setValue('born_abroad', false);
        setValue('name', null);
        setValue('surname', null);
    } else if (['M', 'F'].includes(userData.gender) && userData.date_of_birth === null) {
        setValue('date_of_birth', '', triggerValidation);
        setValue('birth_state_code', 1, triggerValidation);
        setValue('birth_commune_id', null, triggerValidation);
        setValue('born_abroad', false, triggerValidation);
        setValue('name', '', triggerValidation);
        setValue('surname', '', triggerValidation);
    }
}, [userData.gender, userData.date_of_birth]);

  useEffect(() => {
    const callAsync = async () => {
        if (
            location.state !== null &&
            location.state.requestToken !== undefined
        ) {
            skipCheckGenderRef.current = true;
            const {
                data: { data: { userData } },
            } = await modifyRequestData(location.state.requestToken);
            updateUserData({
              ...userData,
              vehicle_model_code: userData.vehicle_model_code.toString(),
              date_of_birth: userData.date_of_birth === null ? '' : userData.date_of_birth
            });
        }
    };
    callAsync();
  }, []);

  if (loading || isSubmitting) {
    return <PageLoading />
  }

  return (
    <>
      <ProgressBar activeStep={index} steps={steps} />
      <div className="mt-2">
        <CenteredPageTitle title="Completa i dati" />
      </div>

      {index === 1 ? <div className="form-container">
        <FormInputText
          label="Targa dell' auto"
          error={errors.vehicle_plate?.message}
          onChange={(value) =>
            updateUserData({ vehicle_plate: value.toUpperCase() })
          }
          value={userData.vehicle_plate}
          valid={isValid('vehicle_plate')}
        />
        <>
          <FormReactSelectBrand
            label={'Marca'}
            values={{
              vehicle_brand_code: userData.vehicle_brand_code
            }}
            error={errors.vehicle_brand_code?.message}
            valid={isValid('vehicle_brand_code')}
            onChange={(item) => updateUserData({
              vehicle_brand_code: item.vehicle_brand_code
            })}
            options={vehicleMakes}
          />

          {userData.vehicle_brand_code && (
            <>
              {isLoadingVehicleModels ? (
                <p className="text-center">Attendere prego...</p>
              ) : (
                <FormReactSelectModel
                  label={'Modello'}
                  values={{
                    vehicle_model_code: userData.vehicle_model_code
                  }}
                  error={errors.vehicle_model_code?.message}
                  valid={isValid('vehicle_model_code')}
                  onChange={(item) => updateUserData({
                    vehicle_model_code: item.vehicle_model_code
                  })}
                  options={vehicleModels}
                />
              )}
            </>
          )}
        </>
      </div> : ''}

      {index === 2 ?
        <>
          <div className="form-container">
            <div>
              <FormInputCheckbox
                registration={register('existing_customer')}
                label=" Cliente Esistente?"
              />
              {userData.existing_customer && (
                <div className="d-flex align-items-start justify-content-center gap-2">
                  <div className="position-relative">
                    <FormInputText
                      placeholder="Nome cliente..."
                      onChange={(value) => onSearchChange(value)}
                      value={searchClient}
                    />
                    {searchResults.length > 0 && (
                      <div
                        className="position-absolute"
                        style={{ top: "80%", left: "0%", right: "0%", zIndex: 50 }}
                      >
                        <ul className="list-group">
                          {searchResults.map((customer, index) => (
                            <li
                              className="list-group-item list-group-item-dark"
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => getCustomerData(customer.id)}
                            >
                              {customer.name.toUpperCase() + (customer.fiscal_code !== null ? ' - (' + customer.fiscal_code + ')' : '')}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <button
                    className="btn btn-questionnaire py-1 px-2 "
                    style={{
                      marginTop: 10,
                      display: "block",
                      width: "150px",
                      border: "3px solid",
                    }}
                  >
                    Cerca cliente
                  </button>
                </div>
              )}

              {selectedClient !== null ? (
                <ActiveClientModal
                  closeClientModal={closeClientModal}
                  municipalities={communes}
                  states={states}
                  formData={formData}
                  client={selectedClient}
                  genders={genders}
                  confirmActiveClient={confirmActiveClient}
                />
              ) : (
                ""
              )}
            </div>

            <FormInputRadio
              options={genders}
              label="Il contraente è"
              name="gender"
              registration={register('gender')}
              error={errors.gender?.message}
            />

            {
              userData.gender === "G" ? (
                <div>
                  <FormInputText
                    placeholder="Ragione Sociale"
                    label="Ragione Sociale"
                    registration={register('business_name')}
                    error={errors.business_name?.message}
                    valid={isValid("business_name")}
                  />
                  <FormInputText
                    placeholder="Partita Iva"
                    label="Partita Iva"
                    registration={register('vat_number')}
                    error={errors.vat_number?.message}
                    valid={isValid("vat_number")}
                  />
                  <FormInputSelect
                    label="Tipologia azienda"
                    registration={register('company_type')}
                    error={errors.company_type?.message}
                    valid={isValid('company_type')}
                    placeholder="-Seleziona-"
                    options={formData.company_types}
                  />
                </div>
              ) : (
                <div>
                  <FormInputText
                    placeholder="Nome"
                    label="Nome"
                    name="name"
                    registration={register('name')}
                    error={errors.name?.message}
                    valid={isValid("name")}
                  />
                  <FormInputText
                    placeholder="Cognome"
                    label="Cognome"
                    name="surname"
                    registration={register('surname')}
                    error={errors.surname?.message}
                    valid={isValid("surname")}
                  />
                  <FormInputDate
                    label="Data di nascita"
                    registration={register('date_of_birth')}
                    minDate="1935-01-01"
                    maxDate="2021-01-01"
                    error={errors.date_of_birth?.message}
                    valid={isValid("date_of_birth")}
                  />
                  <FormReactSelectInputBirthPlace
                    states={states}
                    options={communes}
                    label={'Luogo di nascita'}
                    name={'birth_commune_id'}
                    values={{
                      birth_commune_id: userData.birth_commune_id,
                      birth_state_code: userData.birth_state_code,
                      born_abroad: userData.born_abroad,
                    }}
                    onChange={(value) =>
                      updateUserData({
                        birth_commune_id: value.birth_commune_id,
                        birth_state_code: value.birth_state_code,
                        born_abroad: value.born_abroad,
                      })
                    }
                    valid={isValid('birth_commune_id')}
                    error={errors.birth_commune_id?.message}
                  />
                </div>
              )
            }

            <FormReactSelectResidenceInput
              label="Residenza"
              placeholder={'Comune'}
              options={communes}
              values={{
                residence_commune_id: userData.residence_commune_id,
                postal_code: userData.postal_code
              }}
              onChange={(item) => updateUserData({
                residence_commune_id: item.residence_commune_id,
                postal_code: item.postal_code ?? '',
              })
              }
              error={errors.residence_commune_id?.message}
              valid={isValid('residence_commune_id')}
            >
              <FormInputText
                placeholder="-Codice Postale-"
                label="Codice Postale"
                registration={register('postal_code')}
                error={errors.postal_code?.message}
                valid={isValid('postal_code')}
              />
            </FormReactSelectResidenceInput>

            <FormInputAddress
              placeholder="Indirizzo"
              label="Indirizzo"
              valueAddress={userData.address}
              valueHouse={userData.house_number}
              onAddressChange={(value) => updateUserData({ address: value })}
              onHouseNumberChange={(value) => updateUserData({ house_number: value })}
              addressError={errors.address?.message}
              houseNumberError={errors.house_number?.message}
              validAddress={isValid("address")}
              validHouseNumber={isValid("house_number")}
            />

            <FormInputText
              placeholder="Email"
              label="Email"
              registration={register('email')}
              error={errors.email?.message}
              valid={isValid("email")}
            />

            <FormInputText
              label="Telefono"
              placeholder="Telefono"
              registration={register('phone')}
              error={errors.phone?.message}
              valid={isValid("phone")}
            />

            <FormInputDate
              minDate={getAllowedPolicyEffectDate("min")}
              maxDate={getAllowedPolicyEffectDate("max")}
              registration={register('policy_effective_date')}
              label="Data di inizio della copertura della polizza"
              paragraph="Date valide: da oggi a un anno da oggi"
              error={errors.policy_effective_date?.message}
              valid={isValid("policy_effective_date")}
            />
          </div>
        </>
        : ''}



      {index >= 1 && index <= 2 && (
        <div className="footer-buttons">
          <div className="d-flex justify-content-between">
            {index !== 1 && (
              <button
                className="btn btn-questionnaire back"
                onClick={prevButton}
              >
                Indietro
              </button>
            )}
            <button className="btn btn-questionnaire" onClick={nextButton}>
              {index === 2 ? "Vai ai preventivi" : "Continua"}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default RequestFormFlow