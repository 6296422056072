import axe from "../../services/axios-client/axiosClient";
import caller from "./contractRequestsCaller";
import { generalAlertError } from "../../common/utils/alerts";

const getFormData = () => axe.get("/service-models");

const getCommunes = () => axe.get("/communes");

const getVehicleMakes = () => axe.get("/vehicle-makes");

const getStates = () => axe.get("/states");

export const getInitialFormData = async () => {
  const [statesRes, brandsRes, communesRes, formDataRes] = await Promise.all([
    getStates(),
    getVehicleMakes(),
    getCommunes(),
    getFormData(),
  ]);

  caller.updateFormData({
    loading: false,
    states: statesRes.data.data,
    communes: communesRes.data.data,
    vehicleMakes: brandsRes.data.data,
    formData: formDataRes.data.data,
  });
};

export const resetInitialFormData = () => {
  caller.resetInitialFormData();
};

export const formIndexChange = async (newIndex) => {
  caller.updateFormIndex(newIndex);
};

export const requestGetVehicleModels = async (make_id) => {
  caller.updateFormData({
    isLoadingVehicleModels: true,
  });
  const res = await axe.get("/vehicle-models", {
    params: { make_id },
  });
  caller.updateFormData({
    isLoadingVehicleModels: false,
    vehicleModels: res.data.data,
  });
};

export const requestUpdateFormData = (data) => {
  caller.updateFormData(data);
};

export const requestSearchClient = async (search) => {
  const res = await axe.get("/clients/search/by-name", {
    params: { search },
  });
  caller.updateFormData({
    searchResults: res.data.data,
  });
};

export const requestGetClientData = async (clientId) => {
  const res = await axe.get(`/clients/${clientId}`);
  const resBody = res.data;
  caller.updateFormData({
    selectedClient: {
      ...resBody.data,
      born_abroad: resBody.data.born_abroad ? true : false,
    },
    selectedClientOldData: {
      ...resBody.data,
      born_abroad: resBody.data.born_abroad ? true : false,
    },
    selectedClientId: clientId,
  });
};

export const requestSubmitAnswers = async (data, navigate) => {
  caller.updateFormData({
    isSubmitting: true,
  });

  const res = await axe.post(`/request/contract`, data);

  if (res.status === 400) {
    caller.updateFormData({
      isSubmitting: false,
    });
    generalAlertError(
      res.data.error.map((detail) => detail.message).join("\n")
    );
    return;
  }

  if (!res.data.data) {
    caller.updateFormData({
      isSubmitting: false,
    });
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema"
    );
    return;
  }

  navigate(`/request/${res.data.data.request_token}/contracts`);
};

/**
 * Showing Contracts List
 */

export const loadContracts = async (requestToken) => {
  caller.listContractsPending();

  const res = await axe.get(`/requests/${requestToken}/contracts`);

  if (res.status !== 200) {
    caller.listContractsRejected("An error occured");
    return;
  }

  caller.listContractsFulfilled(res.data.data.contract_request);
};

export const contractUpdateMarkup = async (
  requestToken,
  contractId,
  amount,
) => {
  caller.listContractChangeLoading(true);

  const res = await axe.put(`/contracts/${contractId}/markup`, {
    amount: amount,
  });

  if (res.status === 200) {
    const res = await axe.get(`/requests/${requestToken}/contracts`);
    caller.listContractsFulfilled(res.data.data.contract_request);
    caller.listContractChangeLoading(false);
  } else {
    caller.listContractChangeLoading(false);
  }
};

export const resetContracts = () => {
  caller.listContractsReset();
};

export const getNotSavedContractRequests = async (search) => {
  caller.listNotSavedContractRequestsPending();
  try {
    if (search !== undefined) {
      const res = await axe.get("/not-saved-contract-requests/filtered", {
        params: search,
      });
      caller.listNotSavedContractRequestsSuccess(res.data.data);
    } else {
      const res = await axe.get("/not-saved-contract-requests");
      if (res.status !== 200) {
        generalAlertError(
          "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema"
        );
        return;
      }
      if (res.status === 200) {
        caller.listNotSavedContractRequestsSuccess(res.data.data);
        return;
      }
    }
  } catch (error) {
    caller.listNotSavedContractRequestsReject("Errore Internal");
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema"
    );
    return;
  }
};
