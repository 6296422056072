import { generalAlertError } from "../../common/utils/alerts";
import axe from "../../services/axios-client/axiosClient";
import caller from "./contractsCaller";

export const getPurchasedContracts = async (search) => {
  caller.listPurchasedContractsPending();
  try {
    if (search !== undefined) {
      const res = await axe.get("/purchased-contracts/filter", {
        params: search,
      });
      caller.listPurchasedContractsSuccess(res.data.data);
    } else {
      const res = await axe.get("/purchased-contracts");
      if (res.status !== 200) {
        generalAlertError(
          "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema"
        );
        return;
      }
      if (res.status === 200) {
        caller.listPurchasedContractsSuccess(res.data.data);
        return;
      }
    }
  } catch (error) {
    caller.listPurchasedContractsRejected("Errore Internal");
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema"
    );
    return;
  }
};

export const resetContractsEmmese = () => {
  caller.listContractsResetEmmese();
};
