import styled from 'styled-components';

export const HeaderStyles = styled.div`
    .page-nav{
        padding: 10px 30px;
        border-bottom: 1px solid #dedede;
    }

    .user-info{
        padding: 0 10px;
        font-size: 10px;
    }

    .avatar{
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .avatar img {
        height: 100%;
        width: auto;
    }
    .logout {
        padding: 0 10px;
    }

    .menu {
        font-size: 1.9em;
        padding-bottom: 10px;
        color: #737476;
    }
`;
