import { generalAlertError } from "../../common/utils/alerts";
import axe from "../../services/axios-client/axiosClient";
import caller from "./purchaseCaller";

export const loadContractData = async (contractId, navigate) => {
  caller.getContractPending();
  try {
    const response = await axe.get(`/contracts/purchase/${contractId}`);
    if (
      response.status === 400 &&
      response.data.data.error === "plate_exists"
    ) {
      generalAlertError("Questa Targa ha gia un Contratto Attivo");
      navigate(`/request/${response.data.data.contract_request}/contracts`);
      return;
    }

    if (response.status !== 200) {
      generalAlertError("Errore interno");
      caller.getContractRejected("Errore interno");
      return;
    }

    if (response.status === 200) {
      return caller.getContractSuccess(response.data.data);
    }
  } catch (error) {
    generalAlertError("Errore interno");
    return caller.getContractRejected("Errore interno");
  }
};

export const resetPurchaseData = async () => {
  caller.resetPurchase();
};
