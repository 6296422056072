import React, { useState } from "react"
import Logout from "../../assets/images/log-out.png";
import parseJson from "../helpers/parseJSON";
import { HeaderStyles } from "./Header.styles";

export default function Header({ setShowSidebar }) {

    const user = parseJson(localStorage.getItem('user'));

    const handleClick = () => {
        localStorage.clear();
        window.location.href = "/"
    }

    return (
        <HeaderStyles>
            <nav className="page-nav">
                <div className="d-flex justify-content-between">
                    <span onClick={() => setShowSidebar(true)} className="menu d-lg-none">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20">
                            <path d="M120 816v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z" />
                        </svg>
                    </span>
                    <div className="search-box d-lg-flex d-none align-items-center">
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="user-info">
                            <span className="email">{user.email}</span><br />
                            <h6 className="font-weight-bold">{user.name} {' '} {user.surname}</h6>
                        </div>
                        <span className="logout" onClick={handleClick} style={{ 'cursor': 'pointer' }}>
                            <img src={Logout} height="25" alt="Logout icon" />
                        </span>
                    </div>
                </div>
            </nav>
        </HeaderStyles>
    )
}
