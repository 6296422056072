import React, { useEffect } from 'react'
import * as yup from 'yup';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import generateFiscalCode from '../../common/helpers/generateFiscalCode';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import FormReactSelectResidenceInput from '../../common/formInputs/FormReactSelectResidenceInput';
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace';

const schema = yup.object({
    name: yup
      .string()
      .nullable()
      .when('gender', ([gender], schema) => (gender !== 'G'
        ? schema.required('Il campo è obbligatorio. Inserisci il nome!')
        : schema))
      .matches(
        /^[a-zA-Z ]+$/,
        'Non sono ammessi numeri o caratteri speciali!',
      ),
    surname: yup
      .string()
      .nullable()
      .when('gender', ([gender], schema) => (gender !== 'G'
        ? schema.required('Il campo è obbligatorio. Inserisci il cognome!')
        : schema))
      .matches(
        /^[a-zA-Z ]+$/,
        'Non sono ammessi numeri o caratteri speciali!',
      ),
    gender: yup
      .string()
      .required('Il campo è obbligatorio. Seleziona genere!'),
    fiscal_code: yup
      .string()
      .nullable(),
    phone: yup
      .string()
      .required('Il campo è obbligatorio. Inserisci numero di telefono!'),
    email: yup
      .string()
      .required('Il campo è obbligatorio. Inserisci Email!'),
    date_of_birth: yup
      .string()
      .nullable()
      .when('gender', ([gender], schema) => (gender !== 'G'
        ? schema.required('Il campo è obbligatorio. Seleziona data di nascita!')
        : schema)),
    birth_state_code: yup
      .string()
      .nullable()
      .when('gender', ([gender], schema) => (gender !== 'G'
        ? schema.required('Il campo è obbligatorio. Seleziona il stato estero!')
        : schema)),
    birth_commune_id: yup
      .string()
      .nullable()
      .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (
        gender !== 'G'
          ? (!born_abroad)
            ? schema.required('Il campo è obbligatorio. Inserisci luogo di nascita del propietario!')
            : schema
          : schema
      )),
    born_abroad: yup
      .boolean()
      .required('Il campo è obbligatorio. Seleziona se nato al estero!'),
    residence_commune_id: yup
      .string()
      .required('Il campo è obbligatorio. Seleziona provincia di residenza!'),
    postal_code: yup
      .string()
      .required('Il campo è obbligatorio. Inserisci codice postale!')
      .matches(/^[0-9]{5}$/, 'Il codice postale non e coretto'),
    address: yup
      .string()
      .required('Il campo è obbligatorio. Inserisci indirizzo!'),
    house_number: yup
      .string()
      .required('Il campo è obbligatorio.'),
    business_name: yup
      .string()
      .nullable()
      .when('gender', {
        is: 'G',
        then: () => yup.string().required("Il campo è obbligatorio. Inserisci il nome dell'azienda!"),
      }),
    vat_number: yup
      .string()
      .nullable()
      .when('gender', {
        is: 'G',
        then: () => yup.string().required('Il campo è obbligatorio. Inserisci la tua partita iva!'),
      }),
    company_type: yup
      .string()
      .nullable()
      .when('gender', {
        is: 'G',
        then: () => yup.string().required('Il campo è obbligatorio'),
      }),
});

const ActiveClientModal = ({ closeClientModal, client: selectedClient, genders, confirmActiveClient, municipalities, states, formData }) => {
    const {
        register,
        formState: { errors, touchedFields },
        setValue,
        trigger,
        watch,
      } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
        defaultValues: {
            ...selectedClient,
            born_abroad: selectedClient.born_abroad !== null ? selectedClient.born_abroad : false,
        }
    });
    
    const client = watch();

    const updateClient = (data) => {
        const keys = Object.keys(data)
        keys.forEach(key => {
          setValue(key, data[key], {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        });
        trigger(keys);
    }

    const isValid = (name) => {
      if (errors[name]?.message !== undefined) {
          return false
      }
  
      if (touchedFields[name] === undefined) {
          return false
      }
  
      return true
    }

    const updateLocalClient = (change) => {
        const additionalChanges = {};

        if (change.gender !== undefined && change.gender !== 'G') {
            additionalChanges.company_type = null;
            additionalChanges.business_name = '';
            additionalChanges.vat_number = '';
        }

        if (change.gender !== undefined && change.gender === 'G') {
          additionalChanges.company_type = null;
          additionalChanges.business_name = '';
          additionalChanges.vat_number = '';
        }

        updateClient({
            ...change,
            ...additionalChanges,
        });
    }

    const onChangeWrapper = ({ target: { name, value } }) => {
        updateLocalClient({
            [name]: value
        });
    }

    const getFiscalCode = (birthDate) => {
      let d = birthDate.split("-");
      let fData = {
        name: client.name,
        surname: client.surname,
        gender: client.gender,
        day: d[2],
        month: d[1],
        year: d[0],
      };
  
      if (client.born_abroad) {
        fData.birthplace = states.filter(
          (s) =>
            s.id === client.birth_state_code
        )[0].name;
        fData.birthplaceProvincia = 'EE';
      } else {
        const c = municipalities.find(
          (municipality) =>
            municipality.id === client.birth_commune_id
        );

        fData.birthplace = c.name;
        fData.birthplaceProvincia = c.communal_territory.car_plate_symbol;
      }
  
      return generateFiscalCode(fData);
    };

    const confirmClicked = async () => {
        const res = await trigger(undefined, {
            shouldFocusError: true
        });

        if (res !== true) {
            return;
        } 

        let fiscal_code = null;

        if (client.gender !== 'G') {
          fiscal_code = getFiscalCode(client.date_of_birth);
        }

        confirmActiveClient({
          ...client,
          fiscal_code,
        });
    }

    useEffect(() => {

      const triggerValidation = {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      };
  
      if (client.gender === 'G') {
        setValue('date_of_birth', null);
        setValue('birth_state_code', null);
        setValue('birth_commune_id', null);
        setValue('born_abroad', false);
        setValue('name', null);
        setValue('surname', null);
      } else if (['M', 'F'].includes(client.gender) && client.date_of_birth === null) {
        setValue('date_of_birth', '', triggerValidation);
        setValue('birth_state_code', 1, triggerValidation);
        setValue('birth_commune_id', null, triggerValidation);
        setValue('born_abroad', false, triggerValidation);
        setValue('name', '', triggerValidation);
        setValue('surname', '', triggerValidation);
      }
    }, [client.gender, client.date_of_birth]);

    return (
        <div className="x-modal" id="fastquote-modal">
            <div className="x-modal-cover"></div>
            <div className="general-modal-content">
                <span className="general-modal-close" title="Chiudi" onClick={() => closeClientModal()}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" /></svg>
                </span>
                <div className="modal-header">
                    <h5 className="modal-title">Anagrafica Cliente</h5>
                </div>
                <div className="x-modal-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group d-flex align-items-center justify-content-center flex-column">
                                <label className='form-label'>Genere</label>
                                <div className='d-flex align-items-center justify-content-center'>
                                    {genders.map((gender, index) => (
                                        <div key={index} className='d-flex align-items-center justify-content-center gap-2 me-2'>
                                            <label className='form-label mb-0'>{gender.label}</label>
                                            <input type={'radio'} key={index} className="form-radio" value={gender.value} {...register('gender')} onChange={onChangeWrapper} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Email</label>
                                <FormInputText
                                    registration={register('email')} nostyle
                                    error={errors.email?.message}
                                    valid={isValid('email')}
                                  />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                      { client.gender !== 'G' && <>
                        <div className="col-md-6">
                            <div className="form-group">
                                <FormInputText
                                    label="Nome"
                                    name="name"
                                    registration={register('name')}
                                    error={errors.name?.message}
                                    valid={isValid('name')}
                                  />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <FormInputText
                                    label="Congnome"
                                    registration={register('surname')}
                                    error={errors.surname?.message}
                                    valid={isValid('surname')}
                                />
                            </div>
                        </div>
                      </>}
                      { client.gender === 'G' && <>
                        <div className="row">
                            <div className="col-4">
                                <FormInputText
                                    registration={register('business_name')}
                                    error={errors.business_name?.message}
                                    valid={isValid('business_name')}
                                    label="Ragione sociale"
                                    colClass='col-xl-12 col-md-12 ms-0'
                                />
                            </div>
                            <div className="col-4">
                                <FormInputText
                                    registration={register('vat_number')}
                                    error={errors.vat_number?.message}
                                    valid={isValid('vat_number')}
                                    label="Partita Iva"
                                />
                            </div>
                            <div className="col-4">
                                <FormInputSelect
                                    label="Tipologia azienda"
                                    placeholder="-Seleziona-"
                                    options={formData.company_types}
                                    registration={register('company_type')}
                                    error={errors.company_type?.message}
                                    valid={isValid('company_type')}
                                />
                            </div>
                        </div>
                      </>}
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <FormReactSelectResidenceInput
                                    label="Residenza"
                                    placeholder={'Comune'}
                                    options={municipalities}
                                    values={{
                                        residence_commune_id: client.residence_commune_id,
                                    }}
                                    onChange={(item) => updateLocalClient({
                                        residence_commune_id: item.residence_commune_id,
                                    })}
                                    error={errors.residence_commune_id?.message}
                                    valid={isValid('residence_commune_id')}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <FormInputText
                                  label="Telefono"
                                  registration={register('phone')}
                                  error={errors.phone?.message}
                                  valid={isValid('phone')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <FormInputText 
                                  label="Indirizzo"
                                  registration={register('address')}
                                  error={errors.address?.message}
                                  valid={isValid('address')}
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <FormInputText
                                  label="Nr."
                                  registration={register('house_number')}
                                  error={errors.house_number?.message}
                                  valid={isValid('house_number')}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <FormInputText 
                                  label="Codice Postale"
                                  registration={register('postal_code')} 
                                  error={errors.postal_code?.message}
                                  valid={isValid('postal_code')}
                                />
                            </div>
                        </div>
                    </div>
                    {client.gender !== 'G' && <>
                      <div className="row">
                          <div className="col-md-6">
                              <div className="form-group">
                                  <FormReactSelectInputBirthPlace
                                      states={states}
                                      options={municipalities}
                                      label={'Luogo di nascita'}
                                      name={'birth_commune_id'}
                                      values={{
                                          birth_commune_id: client.birth_commune_id,
                                          birth_state_code: client.birth_state_code,
                                          born_abroad: client.born_abroad,
                                      }}
                                      onChange={(value) =>
                                        updateLocalClient({
                                            birth_commune_id: value.birth_commune_id,
                                            birth_state_code: value.birth_state_code,
                                            born_abroad: value.born_abroad,
                                        })
                                      }
                                      valid={isValid('birth_commune_id')}
                                      error={errors.birth_commune_id?.message}
                                  />
                              </div>
                          </div>
                          <div className="col-md-6 mt-1">
                              <FormInputDate
                                label="Data di nascita"
                                registration={register('date_of_birth')}
                                error={errors.date_of_birth?.message}
                                valid={isValid('date_of_birth')}
                              />
                          </div>
                      </div>
                    </>}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary m-2" onClick={() => confirmClicked()}>Conferma</button>
                    <button type="button" className="btn btn-secondary m-2" onClick={() => closeClientModal()}>Chiudi</button>
                </div>
            </div>
        </div >
    )
}

export default ActiveClientModal;