import axios from "axios";
import { jwtDecode } from "jwt-decode";
import StorageService from "../StorageService";
import { BACKEND_API_URL } from "../../config";

const Storage = new StorageService();

const isTokenExpired = (exp) => Date.now() >= exp * 1000;

export const downloadFile = (fileBlob) => {
  const file = window.URL.createObjectURL(fileBlob);
  window.open(file, "_blank");
};

export const axeRequestInterceptor = async (config) => {
  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json";
  }

  if (!config.headers.Accept) {
    config.headers.Accept = "application/json";
  }

  const jwtToken = Storage.getToken();

  if (jwtToken != null) {
    try {
      const jwtTokenDecoded = jwtDecode(jwtToken);
      if (isTokenExpired(jwtTokenDecoded.exp)) {
        // Check if refresh token is expired
        const jwtRefreshToken = Storage.getRefreshToken();

        if (jwtRefreshToken !== null) {
          const decodedJwtRefreshToken = jwtDecode(jwtRefreshToken);

          if (isTokenExpired(decodedJwtRefreshToken.exp)) {
            Storage.removeRefreshToken();
            Storage.removeToken();
            Storage.removeUser();
          } else {
            const response = await axios.post(
              `${BACKEND_API_URL}/auth/refresh/token`,
              {},
              {
                headers: {
                  Refresh: Storage.getRefreshToken(),
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );

            if (response.status === 403) {
              Storage.removeRefreshToken();
              Storage.removeToken();
              Storage.removeUser();
            } else {
              const { data } = response;

              Storage.setToken(data.access_token);
              Storage.setRefreshToken(data.refresh_token);
              Storage.setUser(data.user);
            }
          }
        } else {
          Storage.removeRefreshToken();
          Storage.removeToken();
          Storage.removeUser();
        }
      }
    } catch (err) {
      // Do nothing
    }

    const finalJwtToken = Storage.getToken();

    if (finalJwtToken !== null) {
      config.headers.Authorization = finalJwtToken;
    }
  }

  config.validateStatus = (status) => status !== 403 && status < 500;

  return config;
};

export const axeResponseInterceptor = async (error) => {
  if (error.response?.status === 403 && Storage.getRefreshToken() !== null) {
    const response = await axios.post(
      `${BACKEND_API_URL}/auth/refresh/token`,
      {},
      {
        headers: {
          Refresh: Storage.getRefreshToken(),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 403) {
      Storage.removeRefreshToken();
      Storage.removeToken();
      Storage.removeUser();
      window.location.href =
        window.location.protocol + "//" + window.location.host + "/login";
      return Promise.reject(error);
    }

    if (response.status !== 200) {
      return Promise.reject(error);
    }

    const { data } = response;

    Storage.setToken(data.access_token);
    Storage.setRefreshToken(data.refresh_token);
    Storage.setUser(data.user);

    error.request.headers.Authorization = Storage.getToken();

    return axios.request(error.request);
  }

  if (error.response?.status === 403) {
    Storage.removeRefreshToken();
    Storage.removeToken();
    Storage.removeUser();
    window.location.href =
      window.location.protocol + "//" + window.location.host + "/login";
    return Promise.reject(error);
  }

  return Promise.reject(error);
};
