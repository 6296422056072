import React, { useEffect, useState } from 'react'
import { BankTransferInfo, BankTransferInfoItem, ErrorMessage, FormInputGroup, FormInputGroupContainer, InfoBoxContainer, InfoItem, LogoContainer, LogoPriceContainer, PayByBankTransfer, PayByCardContainer, PayNowButton, PaymentProofStyled, Price, PriceContainer, QuoteAmountInfo, QuoteAmountList, QuoteInfoContainer, QuoteInformationContainer, QuoteInformationItem, WaitingForPaymentInfo, WaitingForPaymentQuoteInfo, WarningInfo } from './WaitingForPaymentStatus.styles'
import warningIcon from '../../../assets/redesign_icons/warning-icon.svg'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import parseJson from '../../helpers/parseJSON';
import UploaderBonifico from './UploaderBonifico';
import axe from '../../../services/axios-client/axiosClient';
import { generalAlertSuccess } from '../../utils/alerts';
import PageLoading from '../elements/PageLoading';


const schema = yup.object({
  card_number: yup
    .string()
    .required("Il campo è obbligatorio")
    .matches(/^[0-9]+$/, "Dovrebbe contenere solo numeri")
    .min(16, "Il campo dovrebbe contenere 16 numeri"),
  card_expiry: yup
    .string()
    .required("Il campo è obbligatorio")
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "Il formato non è valido")
    .min(5, "Deve contenere una scadenza valida nel formato specificato")
    .max(5, "Deve contenere una scadenza valida nel formato specificato"),
  card_cvv: yup
    .string()
    .required("Il campo è obbligatorio")
    .matches(/^[0-9]+$/, "Dovrebbe contenere solo numeri")
    .min(3, "Dovrebbe contenere almeno 3 numeri")
    .max(4, "Dovrebbe contenere meno di 5 numeri"),
});

const translateError = (error) => {
  const translations = {
    "Invalid expiry month": "Mese di scadenza non valido",
    "Invalid expiry year": "Anno di scadenza non valido",
    "Credit card with wrong check-digit": "Carta di credito con cvv errata",
    "Not numeric characters in credit card number":
      "Caratteri non numerici nel numero della carta di credito",
  };

  if (translations[error] !== undefined) {
    return translations[error];
  }

  return error;
};

function WaitingForPaymentStatus({ contract, contractRequest, collectQuoteData }) {
  const navigate = useNavigate();
  const [quoteError, setQuoteError] = useState(null);
  const [quoteData, setQuoteData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [paymentAuthorizationUrl, setPaymentAuthorizationUrl] = useState(null);

  const user = parseJson(localStorage.getItem('user'));
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset: resetCardForm,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const uploadPaymentProf = (file) => {
    let uploadedFile = file;
    let reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    let fileType = uploadedFile.type
    reader.onload = () => {
      axe.post(`/contract/${contract.id}/upload-payment-proof`, {
        document: reader.result.replace("data:" + fileType + ";base64,", ""),
      }).then(() => {
        navigate('/');
        generalAlertSuccess("Stiamo verificando la prova del pagamento, attendi nostro feedback!");
      });
    };
  }

  const submitData = async (data) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axe.post(`/contract/${contract.id}/pay-with-card`, {
        card: {
          number: data.card_number,
          exp_month: data.card_expiry.split("/")[0],
          exp_year: data.card_expiry.split("/")[1],
          cvv: data.card_cvv,
        },
      });

      if (response.status === 400) {
        setError(translateError(response.data.error));
        setLoading(false);
      } else if (response.status === 500) {
        setError(response.data.error);
        setLoading(false);
      } else if (response.status === 200) {
        setPaymentAuthorizationUrl(response.data.data.user_redirect);
        resetCardForm();
        collectQuoteInformation();
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setError("There was an error");
    }
  };

  const formatInput = (value) => {
    return value.replace(/(\d{2})(\d{0,3})/, '$1/$2');
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\//g, '');
    const formattedValue = formatInput(inputValue);

    e.target.value = formattedValue;

    setValue('card_expiry', formattedValue);
  };

  if (loading) {
    return <PageLoading />
  }


  return (
    <>
      <div className="row">
        <div className="col-12 col-xxl-6">
          <WaitingForPaymentInfo>
            <h3>Informazioni <b>cliente</b></h3>
            <InfoBoxContainer>
              <InfoItem>
                <h4>Nome e Cognome/Ragione Sociale:</h4>
                <p>
                  {contractRequest.request_client.gender !== 'G' ? contractRequest.request_client.name + ' ' + contractRequest.request_client.surname : contractRequest.request_client.business_name}
                </p>
              </InfoItem>
              <InfoItem>
                <h4>Telefono:</h4>
                <p>{contractRequest.request_client.phone}</p>
              </InfoItem>
              <InfoItem>
                <h4>Email:</h4>
                <p>{contractRequest.request_client.email}</p>
              </InfoItem>
              <InfoItem>
                <h4>Indirizzo:</h4>
                <p>{contractRequest.request_client.address} {contractRequest.request_client.house_number !== null && contractRequest.request_client.house_number}, {contractRequest.request_client.residence.name + ' ' + contractRequest.request_client.postal_code}</p>
              </InfoItem>
            </InfoBoxContainer>
          </WaitingForPaymentInfo>
        </div>
        <div className="col-12 col-xxl-6 mt-2 mt-xxl-0">
          <WaitingForPaymentQuoteInfo>
            <QuoteInfoContainer>
              <div>
                <LogoPriceContainer>
                  {/* <LogoContainer>
                    <img src={quoteData.quotation.preventivas_company_id === null ? BACKEND_BASE_URL + quoteData.quotation.company.logo : BACKEND_BASE_URL + '/preventivass/companies/' + quoteData.quotation.preventivas_company.logo_filename} />
                  </LogoContainer> */}
                  <PriceContainer>
                    <Price>
                      <h5>
                        Prezzo annuo:
                      </h5>
                      <h2>
                        &euro;{Number(contract.amount).toLocaleString("it-IT", {
                          minimumFractionDigits: 2,
                        })}<sub style={{ 'fontSize': '14px' }}>iva inclusa</sub>
                      </h2>
                    </Price>
                  </PriceContainer>
                </LogoPriceContainer>
                <QuoteInformationContainer>
                  <QuoteInformationItem>
                    <h6>
                      Prodotto:
                    </h6>
                    <p>
                      {contract.product.name}
                    </p>
                  </QuoteInformationItem>
                  <QuoteInformationItem>
                    <h6>
                      Frequenza pagamento:
                    </h6>
                    <p>
                      Annuo
                    </p>
                  </QuoteInformationItem>
                </QuoteInformationContainer>
              </div>
              <QuoteAmountInfo>
                <QuoteAmountList>
                  <h3>Da Pagare:</h3>
                  <h4>
                    {parseFloat(contract.amount).toFixed(2)} &euro;
                  </h4>
                </QuoteAmountList>
                <QuoteAmountList>
                  <h2>Totale:</h2>
                  <h5>
                    {parseFloat(contract.amount).toFixed(2)} &euro;
                  </h5>
                </QuoteAmountList>
              </QuoteAmountInfo>
            </QuoteInfoContainer>
          </WaitingForPaymentQuoteInfo>
        </div>
      </div>
      {/* {quoteData.quotation_payments.length !== 0 &&
        <div className="row mt-3">
          <div className="col-12 col-xxl-12">
            <PayByCardContainer>
              {quote.with_compensation === 0 && (
                <>
                  {quoteData.quotation_payments.length !== 0 && (
                    <div>
                      <h3>Stato del pagamento</h3>
                      {quoteData.quotation_payments.map((payment) => (
                        <div className="payment-record" key={payment.id}>
                          <div className="field-row">
                            <div className="field-name">
                              <b>Carta</b>
                            </div>
                            <div className="field-value">
                              ************{payment.last_four}
                            </div>
                          </div>
                          <div className="field-row">
                            <div className="field-name">
                              <b>Stato</b>
                            </div>
                            <div className="field-value">
                              {payment.status_name}
                            </div>
                          </div>
                          <div className="field-row">
                            <div className="field-name">
                              <b>Pagato</b>
                            </div>
                            <div className="field-value">
                              {payment.amount.toLocaleString()} &euro;
                            </div>
                          </div>
                          <div className="field-row">
                            <div className="field-name">
                              <b>Ora</b>
                            </div>
                            <div className="field-value">
                              {moment(payment.created_at).format(
                                "DD.MM.YYYY hh:mm"
                              )}
                            </div>
                          </div>
                          {payment.status === 1 &&
                            payment.user_redirect !== null && (
                              <div className="field-row">
                                <a target="_blank" href={payment.user_redirect}>
                                  <div className="btn btn-success">
                                    Autorizza il pagamento
                                  </div>
                                </a>
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </PayByCardContainer>
          </div>
        </div>
      } */}

      <div className="row mt-3">
        <div className="col-12 col-xxl-6">
          <PayByCardContainer>
            <h3>Paga con <b>carta</b> ({parseFloat(contract.amount).toFixed(2)} &euro;)</h3>
            <form onSubmit={handleSubmit(submitData)}>
              <FormInputGroup>
                <label htmlFor="">Carta di credito o di debito</label>
                <input type="text" isInvalid={errors.card_number?.message === undefined ? true : false}  {...register("card_number")} placeholder='Inserisci il numero della carta' />
                <ErrorMessage>
                  {errors.card_number?.message}
                </ErrorMessage>
              </FormInputGroup>
              <FormInputGroupContainer>
                <FormInputGroup>
                  <label htmlFor="">Data di scadenza</label>
                  <Controller
                    name="card_expiry"
                    control={control}
                    render={({ field }) => (
                      <input type="text" isInvalid={errors.card_expiry?.message === undefined ? true : false} {...field} placeholder='MM/AA' maxLength="5"
                        onChange={handleInputChange} />
                    )}
                  />
                  <ErrorMessage>
                    {errors.card_expiry?.message}
                  </ErrorMessage>
                </FormInputGroup>
                <FormInputGroup>
                  <label htmlFor="">Codice di sicurezza (CVV)</label>
                  <input type="text" isInvalid={errors.card_cvv?.message === undefined ? true : false} {...register("card_cvv")} placeholder='CVV' />
                  <ErrorMessage>
                    {errors.card_cvv?.message}
                  </ErrorMessage>
                </FormInputGroup>
              </FormInputGroupContainer>
              <WarningInfo>
                <img src={warningIcon} alt="" />
                <p>
                  Se si fa clic su Invia verrà visualizzata una modalità di conferma, dopo la conferma attendere un paio di minuti per l'elaborazione. Grazie!
                </p>
              </WarningInfo>
              <PayNowButton>
                Procedi con il pagamento
              </PayNowButton>
            </form>
          </PayByCardContainer>
        </div>
        <div className="col-12 col-xxl-6 mt-2 mt-xxl-0">
          <PayByBankTransfer>
            <h3>Paga con <b>bonifico</b></h3>
            {/* {quote.payment_proofs.map((pp, index) => (
              <PaymentProofStyled key={pp.id}>
                <h6>
                  Pagemento: {index + 1} | Pagato: {pp.amount} &euro;
                </h6>

                {pp.payment_proof_valid === 0 && (
                  <div className="alert alert-error">
                    La prova del pagamento non e valida
                  </div>
                )}
                {pp.payment_proof_valid === 1 && (
                  <div className="alert alert-success">
                    La prova del pagamento e valida
                  </div>
                )}

                {pp.payment_proof_file !== null &&
                  pp.payment_proof_valid === null && (
                    <div className="alert alert-warning">
                      Stiamo verificando la prova del pagemento
                    </div>
                  )}

                <embed
                  style={{ marginTop: "10px", width: "100%", height: '200px' }}
                  src={`data:application/pdf;base64,${pp.payment_proof_file}`}
                />
              </PaymentProofStyled>
            ))} */}
            <BankTransferInfo>
              <BankTransferInfoItem>
                Iban: <span>IT60B0326803210052864099270</span>
              </BankTransferInfoItem>
              <BankTransferInfoItem>
                Titolare: <span>Greenia srl</span>
              </BankTransferInfoItem>
              <BankTransferInfoItem>
                Causale: <span>Pagamento polizza [nome + cognome]</span>
              </BankTransferInfoItem>
            </BankTransferInfo>
            <UploaderBonifico note={'CARICA PROVA DEL PAGAMENTO'} onFileUpload={(file) => uploadPaymentProf(file)} />
            {/* {quote.payment_proofs.find((pp) => pp.payment_proof_valid === null) === undefined && (
              <>
                <BankTransferInfo>
                  <BankTransferInfoItem>
                    Iban: <span>IT60B0326803210052864099270</span>
                  </BankTransferInfoItem>
                  <BankTransferInfoItem>
                    Titolare: <span>Greenia srl</span>
                  </BankTransferInfoItem>
                  <BankTransferInfoItem>
                    Causale: <span>Pagamento polizza [nome + cognome]</span>
                  </BankTransferInfoItem>
                </BankTransferInfo>
                <UploaderBonifico note={'CARICA PROVA DEL PAGAMENTO'} onFileUpload={(file) => uploadPaymentProf(file)} />
                {user.compensations === 1 && (
                  <div className="row justify-content-center footer-button">
                    <button className="btn btn-outline-success" onClick={() => compensationConfirmAlertHandle()}>Paga con compensazione mensile</button>
                  </div>
                )}
              </>
            )} */}
          </PayByBankTransfer>
        </div>
      </div>
      {paymentAuthorizationUrl && (
        <div className="x-modal" id="fastquote-modal">
          <div
            className="x-modal-cover"
            onClick={() => setPaymentAuthorizationUrl(null)}
          ></div>
          <div className="general-modal-content">
            <span
              className="general-modal-close"
              title="Quidi"
              onClick={() => setPaymentAuthorizationUrl(null)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
              </svg>
            </span>
            <div className="modal-header">
              <h5 className="modal-title">Autorizza il pagamento</h5>
            </div>
            <div className="x-modal-body">
              <iframe
                style={{ width: "100%", height: "70vh" }}
                src={paymentAuthorizationUrl}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary m-2"
                onClick={() => setPaymentAuthorizationUrl(null)}
              >
                Chiudi
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default WaitingForPaymentStatus