import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "moment/locale/it";
import { Provider } from "react-redux";
import { store } from "../features/store";
import "./App.css";
import DashboardTheme from '../app/theme/style-screens/DashboardTheme'
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import NotFound from '../common/components/NotFound';
import Login from '../screens/Login';
import useAuth from '../hooks/useAuth'
import BrokerHome from "../screens/BrokerHome/BrokerHome";
import RequestFormFlow from "../screens/RequestFlow/RequestFormFlow";
import { RequestContracts } from "../screens/RequestContracts/RequestContracts";
import NotSavedContractRequests from "../screens/NotSavedContractRequests/NotSavedContractRequests";
import PurchaseContract from "../screens/PurchaseContract/PurchaseContract";
import PurchasedContracts from "../screens/PurchasedContracts/PurchasedContracts";

function App() {
  const { isUserLoggedIn } = useAuth();

  if (!isUserLoggedIn) {
    return (
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  return (
    <DashboardTheme>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<BrokerHome />} />
        <Route path="/quote/form" element={<RequestFormFlow />} />
        <Route path="/request/:requestToken/contracts" element={<RequestContracts />} />
        <Route path="/not-saved-contract-request" element={<NotSavedContractRequests />} />
        <Route path="/contract/purchase/:contractId" element={<PurchaseContract />} />
        <Route path="/contratti-aquistati" element={<PurchasedContracts />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </DashboardTheme>
  );
}

function AppWrapper() {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
}

export default AppWrapper;
