import { useState } from "react";
import Header from "../../../common/components/Header";
import Sidebar from "../../../common/components/Sidebar";
import { DashboardThemeStyles } from "./DashboardTheme.styles";

export default function DashboardTheme(props) {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <DashboardThemeStyles>
      <Sidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <div className="page-content">
        <Header setShowSidebar={setShowSidebar} />
        <div className="page-content-items">{props.children}</div>
      </div>
    </DashboardThemeStyles>
  );
}
