import { Paragraph } from "./FormInputRadio.styles"
import { FormGroup, Label } from "./styles/common"

export const FormInputRadio = ({
    label, name, paragraph, options, registration, error, value, onChange
}) => {
    if (registration === undefined) {
        return (
            <FormGroup>
                {label !== undefined &&
                    <Label>{label}</Label>
                }
                {paragraph !== undefined &&
                    <Paragraph>{paragraph}</Paragraph>
                }
                <div className={`d-flex justify-content-center ${(label !== undefined || paragraph !== undefined) ? 'mt-3' : ''}`}>
                    {options.map((opt, index) =>
                        <div key={index.toString()} className="mx-3 d-flex flex-column">
                            <label className="d-flex" style={{ fontWeight: 'bold' }}>{opt.label}
                                <input className="radio" name={name} type="radio" value={opt.value} checked={value == opt.value} onChange={() => onChange(opt.value)} />
                            </label>
                        </div>
                    )}
                </div>
                {error !== undefined &&
                    <div className="general-input-error text-center">
                        {error}
                    </div>
                }
            </FormGroup>
        )
    }

    return (
        <FormGroup>
            {label !== undefined &&
                <Label>{label}</Label>
            }
            {paragraph !== undefined &&
                <Paragraph>{paragraph}</Paragraph>
            }
            <div className={`d-flex justify-content-center ${(label !== undefined || paragraph !== undefined) ? 'mt-3' : ''}`}>
                {options.map((opt, index) =>
                    <div key={index.toString()} className="mx-3 d-flex flex-column">
                        <label className="d-flex" style={{ fontWeight: 'bold' }}>{opt.label}
                            <input className="radio" name={name} type="radio" value={opt.value} {...registration} />
                        </label>
                    </div>
                )}
            </div>
            {error !== undefined &&
                <div className="general-input-error text-center">
                    {error}
                </div>
            }
        </FormGroup>
    )
}
