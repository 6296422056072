import styled from "styled-components";

export const ModificaDatiButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  p {
    margin-bottom: 0;
    color: #0c85e9;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  img {
    max-width: 25px;
  }

  @media only screen and (max-width: 900px) {
    p {
      font-size: 14px;
    }

    img {
      max-width: 15px;
    }
  }
`;

export const EstimateContainer = styled.div`
  margin: 30px 0;
  h4 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    color: #1a1921;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media only screen and (max-width: 900px) {
    margin: 20px 0;
    h4 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }
`;
