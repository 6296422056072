import styled from "styled-components";

export const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  @media (max-width: 1200px) {
    overflow: hidden;
  }
`;

export const StepOne = styled.div`
  width: 100%;

  h6 {
    color: #8b8e97;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 10px;
    margin-bottom: 0;
  }

  h5 {
    color: #0d1723;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 0px;
    margin-bottom: 0;
  }
`;

export const StepFour = styled.div`
  text-wrap: nowrap;

  h6 {
    color: #8b8e97;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 10px;
    margin-bottom: 0;
  }

  h5 {
    color: #0d1723;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 0px;
    margin-bottom: 0;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 1200px) {
    width: 210px;

    img {
      width: 25px;
    }
  }
`;

export const Circle = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};

  @media (max-width: 1200px) {
    width: 20px;
    height: 20px;
  }
`;

export const ProgressLine = styled.div`
  background-color: ${(props) => props.backgroundColor};
  border-radius: 100px;
  width: 90%;
  height: 5px;
  margin: 0 auto;
`;

export const ProgressContainerLast = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

export const BadgeProgress = styled.div`
  margin-top: 5px;
  border-radius: 110px;
  background: ${(props) => props.backgroundColor};
  padding: 5px 30px;
  display: inline-block;
  color: ${(props) => props.textColor};
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
`;
