import { FormGroup, Label } from "./styles/common";

export const FormInputSelect = ({
    label, placeholder, options, registration, error, valid, disabled, value, onChange,
}) => {

    const handleChange = (e) => {
        onChange(e.target.value);
    }

    if (registration === undefined) {
        return (
            <FormGroup>
                <Label>{label}</Label>
                <select className={`form-control text-center fw-bold ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} disabled={disabled ?? false} onChange={handleChange} value={value}>
                    <option value="" disabled className="fw-normal">{placeholder}</option>
                    {options.map((opt) =>
                        <option key={opt.id ? opt.id.toString() : opt} value={opt.id ? opt.id : opt}>{opt.id ? opt.name : opt}</option>
                    )}
                </select>
                <div className="invalid-feedback">
                    {error}
                </div>
            </FormGroup>
        );
    }

    return (
        <FormGroup>
            <Label>{label}</Label>
            <select className={`form-control text-center fw-bold ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} disabled={disabled ?? false} {...registration}>
                <option value="" disabled className="fw-normal">{placeholder}</option>
                {options.map((opt) =>
                    <option key={opt.id ? opt.id.toString() : opt} value={opt.id ? opt.id : opt}>{opt.id ? opt.name : opt}</option>
                )}
            </select>
            <div className="invalid-feedback">
                {error}
            </div>
        </FormGroup>
    );
}
