import parseJson from "../common/helpers/parseJSON";
import stringifyJson from "../common/helpers/stringifyJSON";

export default class StorageService {
  getToken() {
    return localStorage.getItem("jwtToken");
  }

  setToken(userToken) {
    localStorage.setItem("jwtToken", userToken);
  }

  removeToken() {
    localStorage.removeItem("jwtToken");
  }

  getUser() {
    const user = localStorage.getItem("user");
    if (user !== null) {
      return parseJson(user);
    }
    return null;
  }

  setUser(user) {
    localStorage.setItem("user", stringifyJson(user));
  }

  removeUser() {
    localStorage.setItem("user");
  }

  setRefreshToken(refreshToken) {
    localStorage.setItem("jwtRefreshToken", refreshToken);
  }

  getRefreshToken() {
    return localStorage.getItem("jwtRefreshToken");
  }

  removeRefreshToken() {
    localStorage.removeItem("jwtRefreshToken");
  }

  setVehicleQuoteAnswers(answers) {
    localStorage.setItem("vqa", stringifyJson(answers));
  }

  getVehicleQuoteAnswers() {
    return parseJson(localStorage.getItem("vqa"));
  }

  removeVehicleQuoteAnswers() {
    localStorage.removeItem("vqa");
  }

  setVehicleOwnerData(owner) {
    return localStorage.setItem("vo", stringifyJson(owner));
  }

  removeVehicleOwnerData() {
    localStorage.removeItem("vo");
  }

  getVehicleOwnerData() {
    return parseJson(localStorage.getItem("vo"));
  }

  setVehicleDriverData(driver) {
    return localStorage.setItem("vd", stringifyJson(driver));
  }

  getVehicleDriverData() {
    return parseJson(localStorage.getItem("vd"));
  }

  removeVehicleDriverData() {
    localStorage.removeItem("vd");
  }
}
