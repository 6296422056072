import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BrokerHomeStyles } from "./BrokerHome.styles";

const BrokerHome = () => {

  return (
    <BrokerHomeStyles>
      <h1 className="screen-title">Home</h1>
      {/* <div className="custom-row">
        <div id="client-search" className="custom-card">
          <div className="custom-card-header">
            <div className="custom-card-section" style={{ flex: 2 }}>
              <h3>Cerca Cliente</h3>
            </div>
            <div className="custom-card-section"></div>
          </div>
          <div
            className="custom-card-body"
            style={{ position: "relative", overflowY: "visible" }}
          >
            <div className="custom-card-section" style={{ flex: 2 }}>
              <h6>Scrivi nome del cliente</h6>
              <br />
              <div className="search-input-container">
                <div className="search-input-icon">
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <div className="search-input-divider"></div>
                <input
                  className="search-input-content"
                  placeholder="Nome del cliente"
                  value={search}
                  onChange={onSearchChange}
                />
                <div className="search-input-results">
                  {search !== "" && searchResults.length === 0
                    ? "Nessun risultato"
                    : searchResults.map((result) => {
                        return (
                          <div
                            key={result.id}
                            className="search-input-result"
                            onClick={() => navigateToCustomerPage(result.id)}
                          >
                             {result.name.toUpperCase() + (result.fiscal_code !== null ? ' - ('+result.fiscal_code+')' : '')}
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
            <DottedDivider />
            <div className="custom-card-section">
              <br />
              <h6>Vai alle offerte</h6>
              <br />
              <div
                className="new-quotation-button"
                onClick={() => navigate("/quote")}
              >
                FAI UN PREVENTIVO
              </div>
            </div>
          </div>
        </div>
        <div id="client-search-mobile">
          <div className="custom-card">
            <div className="custom-card-header">
              <div className="custom-card-section" style={{ flex: 2 }}>
                <h3>Cerca Cliente</h3>
              </div>
              <div className="custom-card-section"></div>
            </div>
            <div
              className="custom-card-body"
              style={{ position: "relative", overflowY: "visible" }}
            >
              <div className="custom-card-section" style={{ flex: 2 }}>
                <h6>Scrivi nome del cliente</h6>
                <div className="search-input-container">
                  <div className="search-input-icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                  <div className="search-input-divider"></div>
                  <input
                    className="search-input-content"
                    placeholder="Nome del cliente"
                    value={search}
                    onChange={onSearchChange}
                  />
                  <div className="search-input-results">
                    {search !== "" && searchResults.length === 0
                      ? "Nessun risultato"
                      : searchResults.map((result) => {
                          return (
                            <div
                              key={result.id}
                              className="search-input-result"
                              onClick={() => navigateToCustomerPage(result.id)}
                            >
                              {result.name.toUpperCase() + (result.fiscal_code !== null ? ' - ('+result.fiscal_code+')' : '')}
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="custom-card">
            <div className="custom-card-body custom-card-body--body-only">
              <div className="custom-card-section">
                <br />
                <h6>Vai alle offerte</h6>
                <div
                  className="new-quotation-button"
                  onClick={() => navigate("/quote")}
                >
                  FAI UN PREVENTIVO
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="stats-info" className="custom-card">
          <div className="custom-card-header">
            <div className="custom-card-section">
              <h3>Quotazioni</h3>
            </div>
            <DottedDivider />
            <div className="custom-card-section">
              <h3>Portofolio</h3>
            </div>
            <DottedDivider />
            <div className="custom-card-section">
              <h3>Combined Ratio</h3>
            </div>
          </div>
          <div className="custom-card-body">
            <div className="custom-card-section">
              <div className="stats-field">
                <div className="stats-field__description">Emmese</div>
                <div className="stats-field__price">
                  {total_quotations_emmese}
                </div>
                <div className="stats-field__price-subscription">
                  Totale: {total_quotations_started}
                </div>
              </div>
            </div>
            <DottedDivider />
            <div className="custom-card-section">
              <div className="stats-field">
                <div className="stats-field__description">Totale</div>
                <div className="stats-field__price">
                  {Number(total_quotations_paid_amount).toLocaleString(
                    "it-IT",
                    { minimumFractionDigits: 2 }
                  )}
                  &euro;
                </div>
                <div className="stats-field__price-subscription">
                  Sinistri:{" "}
                  {Number(total_spent_in_accidents).toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                  })}
                  &euro;
                </div>
              </div>
            </div>
            <DottedDivider />
            <div className="custom-card-section">
              <div className="ratio-statistic-custom">
                <div className="ratio-statistic-custom-percentage-value">
                  {progress}%
                </div>
                <VictoryPie
                  padAngle={0}
                  // used to hide labels
                  labelComponent={<span />}
                  innerRadius={70}
                  radius={60}
                  width={150}
                  height={150}
                  data={[
                    { key: "", y: progress },
                    { key: "", y: 100 - progress },
                  ]}
                  colorScale={["#19B3A6", "#EEEEEE"]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="stats-info-mobile">
        <div className="custom-row">
          <div className="custom-card">
            <div className="custom-card-header">
              <div className="custom-card-section">
                <h3>Quotazioni</h3>
              </div>
            </div>
            <div className="custom-card-body">
              <div className="custom-card-section">
                <div className="stats-field__description">Emmese</div>
                <div className="stats-field__price">
                  {total_quotations_emmese}
                </div>
                <div className="stats-field__price-subscription">
                  Totale: {total_quotations_started}
                </div>
              </div>
            </div>
          </div>
          <div className="custom-card">
            <div className="custom-card-header">
              <div className="custom-card-section">
                <h3>Portofolio</h3>
              </div>
            </div>
            <div className="custom-card-body">
              <div className="custom-card-section">
                <div className="stats-field__description">Totale</div>
                <div className="stats-field__price">
                  {Number(total_quotations_paid_amount).toLocaleString(
                    "it-IT",
                    { minimumFractionDigits: 2 }
                  )}
                  &euro;
                </div>
                <div className="stats-field__price-subscription">
                  Sinistri:{" "}
                  {Number(total_spent_in_accidents).toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                  })}
                  &euro;
                </div>
              </div>
            </div>
          </div>
          <div className="custom-card">
            <div className="custom-card-header">
              <div className="custom-card-section">
                <h3>Combined Ratio</h3>
              </div>
            </div>
            <div className="custom-card-body">
              <div className="custom-card-section">
                <div className="ratio-statistic-custom">
                  <div className="ratio-statistic-custom-percentage-value">
                    {progress}%
                  </div>
                  <VictoryPie
                    padAngle={0}
                    // used to hide labels
                    labelComponent={<span />}
                    innerRadius={30}
                    radius={25}
                    width={100}
                    height={100}
                    data={[
                      { key: "", y: progress },
                      { key: "", y: 100 - progress },
                    ]}
                    colorScale={["#19B3A6", "#EEEEEE"]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="custom-row flex-wrap">
        <div className="custom-card latest-updates-card">
          <div className="custom-card-header" style={{ position: "relative" }}>
            <h3>Ultimi aggiornamenti</h3>
            <div
              className="go-to-page-button"
              onClick={() => navigate("/latest-updates")}
            >
              <FontAwesomeIcon icon={faArrowRightLong} />
            </div>
          </div>
          <div className="custom-card-body h--flex-column">
            <table className="custom-table">
              <tbody>
                {latest_updates.length === 0 ? (
                  <div className="custom-table-empty">Nessun dato</div>
                ) : (
                  latest_updates.map((lu, index) => {
                    return (
                      <tr className="custom-table-row" key={index}>
                        <td className="latest-updates-time">
                          {moment(lu.created_at).fromNow(true)}
                        </td>
                        <td className="latest-updates-contract">
                          { lu.customer.business_name !== '' && lu.customer.business_name !== null &&
                            <>{lu.customer.business_name}</>
                          }
                          { lu.customer.business_name === '' || lu.customer.business_name === null &&
                            <>{lu.customer?.name} {lu.customer?.surname}</>
                          }
                        </td>
                        <td>{lu.product.name}</td>
                        <td>{lu.status.label}</td>
                        <td className="latest-updates-actions">
                          <div
                            className="action-button"
                            onClick={() =>
                              sendToInsuranceRequestQuotationsList(lu)
                            }
                          >
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            {latest_updates.length !== 0 && (
              <div
                className="see-more-section"
                onClick={() => navigate("/latest-updates")}
              >
                <div className="see-more-content">Vedi altro</div>
                <div className="see-more-arrow">
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="custom-card agendas-card">
          <div className="custom-card-header" style={{ position: "relative" }}>
            <h3>Agenda</h3>
          </div>
          <div className="custom-card-body d-flex h--flex-column justify-content-between" style={{ height: '100%'}}>
            { agends_loading && 
              <div className="text-center">
                <img className="loading-gif" src={LoadingGif} />
                <br />
                Attendere prego
              </div>
            }

            { !agends_loading && 
              <>
                <table className="custom-table" style={{tableLayout: 'fixed' }}>
                  <thead>
                    {
                      [0, 1, 2, 3, 4, 5, 6].map(val => (
                        <th key={val}>
                          <div className="month-name">{capitalize(moment().add(agends_week, 'w').add(val, 'd').format('MMMM'))}</div>
                          <div className="date-day">{moment().add(agends_week, 'w').add(val, 'd').format('D')}</div>
                          <div className="day-name">{capitalize(moment().add(agends_week, 'w').add(val, 'd').format('dddd'))}</div>
                        </th>
                      ))
                    }
                  </thead>
                  <tbody>
                    {
                      Array(processedAgends.rows_count).fill('a').map((v, index) => (
                        <tr className="custom-table-row" key={index}>
                          {
                            [0, 1, 2, 3, 4, 5, 6].map(val => {
                              const ad = getAgentData(val, index);

                              if (ad === undefined) {
                                return <td></td>
                              }

                              return (
                                <td className="agent-td" onClick={() => agendaSentToPurchase(ad)}>
                                  <div className="agent-td-product">{ad.product_name}</div>
                                  { ad.customer.gender !== 'G' && 
                                    <div className="agent-td-customer">{ad.customer.name} {ad.customer.surname}</div>
                                  }
                                  { ad.customer.gender === 'G' && 
                                    <div className="agent-td-customer">{ad.customer.business_name}</div>
                                  }
                                  <div className="agent-td-type">{getAgendType(ad.type)}</div>
                                </td>
                              )
                            })
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>

                <div className="custom-row justify-content-center">
                  <div className="see-more-section" onClick={() => getAgendsByWeek(agends_week - 1)}>
                    <div className="see-more-arrow">
                      <FontAwesomeIcon icon={faArrowLeftLong} />
                    </div>
                    <div className="see-more-content">Guarda la settimana scorsa</div>
                  </div>
                  <div className="see-more-section" onClick={() => getAgendsByWeek(agends_week + 1)}>
                    <div className="see-more-content">Guarda la settimana prossima</div>
                    <div className="see-more-arrow">
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>

      <h1 className="screen-title">
        Le ultime notizie dal mercato assicurativo
      </h1>

      <div className="custom-row flex-wrap">
        {latest_articles.map((article, index) => {
          return <ArticleCard article={article} key={index} />;
        })}
      </div> */}
    </BrokerHomeStyles>
  );
};

export default BrokerHome;
