import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contract: {
    loading: true,
    error: null,
    contract: null,
    contractStatus: 1,
  },
};

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    getContractPending: (state) => {
      state.contract.loading = true;
      state.contract.error = null;
    },
    getContractRejected: (state, { payload }) => {
      state.contract.loading = false;
      state.contract.error = payload;
    },
    getContractSuccess: (state, { payload }) => {
      state.contract.loading = false;
      state.contract.contract = payload.data;
      state.contract.contractStatus = payload.data.status;
    },
    resetPurchase: (state) => {
      state.contract = { ...initialState.contract };
    },
  },
});
