import styled from "styled-components";

export const NotSavedContractStyles = styled.div`
  .btn-to-quote {
    background-color: #0c85e9;
    cursor: pointer;
    color: #ffffff;
    transition: all 300ms ease-in-out;
    border: 1px solid #0c85e9;
  }

  .btn-to-quote a {
    color: #ffffff;
  }

  .btn-to-quote:hover a {
    color: #0c85e9;
  }

  .btn-to-quote:hover {
    background-color: transparent;
    color: #0c85e9;
    border: 1px solid #0c85e9;
  }

  @media only screen and (min-width: 768px) {
    .quotation-filters {
      position: fixed;
      right: 0;
    }
  }

  /* .search-input {
        width: 60%;
    }

    .search-plate {
        width: 20%;
    } */

  .search-input.form-control:focus,
  .search-plate.form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 166 81 / 25%) !important;
    border-color: rgb(0 166 81 / 50%) !important;
  }

  .search-btn {
    display: table;
    margin: 0 auto;
    width: 50%;
    border: 2px solid #0c85e9;
    background-color: #0c85e9;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding: 6px 16px;
  }
`;
