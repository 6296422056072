import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RequestContractsStyles } from './RequestContracts.styles'
import { EstimateContainer } from './Redesign.styles'
import { loadContracts, resetContracts } from '../../features/contractRequests/contractRequestsActions'
import PageLoading from '../../common/components/elements/PageLoading'
import { RequestCardInfo } from './RequestCardInfo'
import { GuaranteesDefaultStateRequest } from './GuaranteesDefaultStateRequest'
import { ContractCard } from './ContractCard'
import { PageKeepLoader } from '../../common/components/elements/PageKeepLoader'

export const RequestContracts = () => {
    const navigate = useNavigate()
    const { requestToken } = useParams()
    const { loading, markupLoading, contractRequest } = useSelector((store) => store.utilities.list_contracts)

    const modifyData = () => {
        navigate('/quote/form', {
            state: { requestToken }
        });
    }

    const collectData = () => {
        loadContracts(requestToken);
    }

    useEffect(() => {
        collectData();

        return () => {
            resetContracts();
        }
    }, []);

    if (loading) {
        return <PageLoading />
    }

    return (
        <RequestContractsStyles>
            {markupLoading === true &&
                <PageKeepLoader />
            }
            <div className="resultsPage">
                <div className="row">
                    <div className="col-12 col-xxl-4 mb-2 mb-xxl-0">
                        <RequestCardInfo contractRequest={contractRequest} modificaDati={modifyData} />
                    </div>
                    <div className="col-12 col-xxl-8">
                        <GuaranteesDefaultStateRequest />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <EstimateContainer>
                            <h4>
                                Contratti
                            </h4>
                            <p>
                                Inizio Copertura: {new Date(contractRequest.start_date).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                            </p>
                        </EstimateContainer>
                    </div>
                </div>

                {contractRequest.contracts.map(((contract, index) =>
                    <div className="row mb-4" key={index}>
                        <div className="col-12">
                            <ContractCard contract={contract} />
                        </div>
                    </div>
                ))}
            </div>
        </RequestContractsStyles>
    )
}