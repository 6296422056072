
import { CenteredPageTitleStyles } from "./CenteredPageTitle.styles";

export default function CenteredPageTitle(props) {
    if (props.children)
        return (
            <CenteredPageTitleStyles>
                <div className="title-h2 divider">{props.children}</div>
            </CenteredPageTitleStyles>
        )
    return (
        <CenteredPageTitleStyles>
            <div className="title-h2 divider">{props.title}</div>
        </CenteredPageTitleStyles>
    )
}
