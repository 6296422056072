import caller from "./userCaller";
import StorageService from "../../services/StorageService";
import axe from "../../services/axios-client/axiosClient";

const storage = new StorageService();

export const attemptLogin = async ({ email, password }) => {
  caller.loginPending();

  try {
    const response = await axe.post("/auth/login", { email, password });
    if (response.status === 401) {
      caller.loginRejected("Credenziali non valide");
      return;
    }

    if (response.status !== 200) {
      caller.loginRejected("Errore interno");
      return;
    }

    const { user, access_token: token, refresh_token } = response.data;

    storage.setUser(user);
    storage.setToken(token);
    storage.setRefreshToken(refresh_token);

    caller.loginSuccess({ user, token });
  } catch (err) {
    caller.loginRejected("Errore interno");
  }
};

export const brokerData = async (brokerId) => {
  caller.brokerDataPending();
  try {
    const response = await axe.get(`/${brokerId}`);

    caller.brokerDataSuccess({
      loading: false,
      broker: response.data.data.broker,
      documents: response.data.data.documents,
      reports: response.data.data.reports,
      bills: response.data.data.bills,
    });
    return;
  } catch (error) {
    caller.brokerDataRejected(error);
  }
};
