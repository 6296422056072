import React, { useEffect, useState } from 'react'
import { ContractCardStyles, MarkupContainer, MarkupTitle, AddGuaranteesButton, AquistaButton, AquistaButtonsContainer, GuaranteesList, GuaranteesListItem, LeftCol, LogoContainer, LogoPriceContainer, Price, PriceContainer, QuoteInformationContainer, QuoteInformationItem, RightCol, SelectedGuaranteesContainer, MarkupSliderContainer, MarkupPrice, MarkupSlider } from './ContractCard.styles'
import { DefaultInfo } from './GuaranteesDefaultStateRequest.styles'
import warningIcon from '../../assets/redesign_icons/warning-icon.svg'
import { Range } from 'react-range'
import { useNavigate } from 'react-router-dom';
import CarImage from '../../assets/images/car.png';
import { contractUpdateMarkup } from '../../features/contractRequests/contractRequestsActions'

const STEP = 1;
const MIN = 0;
const MAX = 100;

export const ContractCard = ({ contract }) => {
  const [val, setVal] = useState({ values: [0] })
  const navigate = useNavigate()

  const aquista = () => {
    navigate(`/contract/purchase/${contract.id}`)
  }

  const addMarkupPercentage = () => {
    contractUpdateMarkup(contract.contract_request_id, contract.id, val.values[0])
  }

  useEffect(() => {
    if (contract) {
      setVal({
        values: [contract.markup]
      })
    }
  }, [contract])

  return (
    <ContractCardStyles>
      <LeftCol>
        <LogoPriceContainer>
          <LogoContainer>
            <img src={CarImage} />
          </LogoContainer>
          <PriceContainer>
            <Price>
              <h5>
                Prezzo annuo:
              </h5>
              <h2>
                €{parseFloat(contract.amount).toFixed(2)} <sub style={{ 'fontSize': '12px', 'color': 'red' }}>iva inclusa</sub>
              </h2>
            </Price>
          </PriceContainer>
        </LogoPriceContainer>
        <QuoteInformationContainer>
          <QuoteInformationItem>
            <h6>
              Prodotto:
            </h6>
            <p>
              {contract.product.name}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Descrizione:
            </h6>
            <p>
              {contract.product.description}
            </p>
          </QuoteInformationItem>
        </QuoteInformationContainer>
        <MarkupContainer>
          <div className="row">
            <div className="col-12 col-xxl-8">
              <MarkupTitle>
                Seleziona Markup Del Prodotto
              </MarkupTitle>
              <DefaultInfo>
                <img src={warningIcon} alt="" />
                <p>
                  Fai attenzione perché questo markup non può essere modificato se prosegui con il step successivo.
                </p>
              </DefaultInfo>
            </div>
            <div className="col-12 col-xxl-4">
              <AddGuaranteesButton onClick={() => addMarkupPercentage()}>Aggiungi</AddGuaranteesButton>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MarkupSliderContainer>
                <MarkupPrice>
                  &euro; 0.00
                </MarkupPrice>
                <MarkupSlider>
                  <Range
                    values={val.values}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={(values) => contract.is_aquista === 1 ? () => { } : setVal({ values })}
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          height: "36px",
                          display: "flex",
                          width: "95%",
                          margin: '0 auto'
                        }}
                      >
                        <div className='range-responsive-slide'
                          ref={props.ref}
                          style={{
                            height: "20px",
                            width: "100%",
                            borderRadius: "140px",
                            alignSelf: "center",
                            backgroundColor: '#D1E9FD'
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div className='range-responsive-circle'
                        {...props}
                        style={{
                          ...props.style,
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#0C85E9",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: 'none',
                          boxShadow: 'none',
                          outline: 'none',
                        }}
                      >
                        <div className='range-responsive-text'
                          style={{
                            position: 'absolute',
                            top: '-35px',
                            width: '100px',
                            color: "#319CF4",
                            textAlign: 'center',
                            fontFamily: 'Poppins',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '24px',
                          }}
                        >
                          &euro; {val.values[0].toFixed(2)}
                        </div>
                      </div>
                    )}
                  />
                </MarkupSlider>
                <MarkupPrice>
                  &euro; 100.00
                </MarkupPrice>
              </MarkupSliderContainer>
            </div>
          </div>
        </MarkupContainer>
      </LeftCol>
      <RightCol>
        <SelectedGuaranteesContainer>
          <h2><b>Markup</b> Aggiunto</h2>
          <GuaranteesList>
            <GuaranteesListItem>
              <h6>
                <span></span> Markup
              </h6>
              <p>
                &euro;{parseFloat(val.values[0]).toLocaleString('en-US')}
              </p>
            </GuaranteesListItem>
          </GuaranteesList>
        </SelectedGuaranteesContainer>
        <AquistaButtonsContainer>
          <AquistaButton onClick={() => aquista()}>Acquista</AquistaButton>
        </AquistaButtonsContainer>
      </RightCol>
    </ContractCardStyles>
  )
}