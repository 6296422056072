import { FormGroup, Label } from "./styles/common";

export const FormInputAddress = ({
    label, valueAddress, valueHouse, addressError, houseNumberError,
    validAddress, validHouseNumber, onAddressChange, onHouseNumberChange,
}) => {
    const handleAddressChange = ({ target: { value } }) => {
        onAddressChange(value);
    }

    const handleHouseNumberChange = ({ target: { value } }) => {
        onHouseNumberChange(value);
    }

    return (
        <div className="row">
            <div className="col-12 col-md-8">
                <FormGroup>
                    <Label>{label}</Label>
                    <input type="text" className={`form-control text-center ${addressError ? 'is-invalid' : ''} ${validAddress ? 'is-valid' : ''}`}
                        onChange={handleAddressChange} value={valueAddress}
                    />
                    <div className="invalid-feedback">{addressError}</div>
                </FormGroup>
            </div>
            <div className="col-12 col-md-4">
                <FormGroup>
                    <Label>N.</Label>
                    <input type="number" className={`form-control text-center ${houseNumberError ? 'is-invalid' : ''} ${validHouseNumber ? 'is-valid' : ''}`}
                        onChange={handleHouseNumberChange} value={valueHouse} />
                    <div className="invalid-feedback">{houseNumberError}</div>
                </FormGroup>
            </div>
        </div>
    )
}

