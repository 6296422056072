import React, { useEffect, useRef, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { FormInputCheckbox } from './FormInputCheckbox';
import { Label, SelectContainer } from './styles/common';

function FormReactSelectInputBirthPlace({ options, states, values, onChange, valid, error, label, name }) {
  const [perpOptions, setPerpOptions] = useState([])
  const [prepStates, setPrepStates] = useState([])
  const [inputText, setInputText] = useState('');

  let defaultBorder = '#CBCBCD';

  const filterMunicipalities = (inputValue) => {
    if (inputValue.length >= 3) {
      return perpOptions.filter((i) =>
        i.label.toLowerCase().startsWith(inputValue.toLowerCase())
      );
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterMunicipalities(inputValue));
      }, 500);
    });

  const filterStates = (inputValue) => {
    if (inputValue.length >= 3) {
      return prepStates.filter((i) =>
        i.label.toLowerCase().startsWith(inputValue.toLowerCase())
      );
    }
  };

  const promiseOptionsStates = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterStates(inputValue));
      }, 500);
    });

  const handleChange = (value) => {
    if (value.born_abroad !== undefined) {
      if (value.born_abroad) {
        onChange({
          born_abroad: value.born_abroad,
          birth_commune_id: null,
          birth_state_code: 1,
        })

      } else {
        onChange({
          born_abroad: value.born_abroad,
          birth_state_code: 1,
          birth_commune_id: null,
        })
      }
      return
    }

    if (!value.born_abroad) {
      if (value.birth_commune_id !== undefined || value.birth_commune_id !== '') {
        let commune = options.find(opt => opt.id === value.birth_commune_id)

        if (commune !== undefined) {
          onChange({
            birth_commune_id: commune.id,
            born_abroad: false,
            birth_state_code: 1
          })
          return
        }
      }
    }

    if (value.birth_commune_id === null || value.birth_commune_id === '') {
      onChange({
        birth_commune_id: null,
        born_abroad: false,
        birth_state_code: 1
      })
      return
    }

    if (value.birth_state_code !== null || value.birth_state_code !== '' || value.birth_state_code !== undefined) {
      onChange({
        birth_state_code: value.birth_state_code,
        birth_commune_id: null,
        born_abroad: true,
      })
    }
  }

  const handleInputChange = (inputValue) => {
    setInputText(inputValue);
  };

  const handleMenuClose = () => {
    if (!values.birth_commune_id && inputText) {
      let startingWithOptions = perpOptions.filter(option =>
        option.label.toLowerCase().startsWith(inputText.toLowerCase())
      );
      let firstStartingOption = startingWithOptions[0];
      if (firstStartingOption) {
        onChange({
          birth_commune_id: firstStartingOption.value,
          born_abroad: false,
          birth_state_code: 1
        });
      }
    }
  };

  useEffect(() => {
    setPerpOptions(options.map((i) => ({ value: i.id, label: i.name + ` (${i.communal_territory.car_plate_symbol})` })))
    setPrepStates(states.map((i) => ({ value: i.id, label: i.name })))
  }, [options])

  return (
    <div className="form-input-container">
      <div className="row">
        <div className="col-12 col-md-12">
          <Label>{label}</Label>
          <SelectContainer>
            <AsyncSelect
              onMenuClose={handleMenuClose}
              onInputChange={handleInputChange}
              loadOptions={promiseOptions}
              defaultOptions
              isSearchable
              placeholder={''}
              name={name}
              isDisabled={values.born_abroad === true ? true : false}
              value={values.born_abroad === false ? options.filter(opt => opt.id === values.birth_commune_id).map(o => ({ value: o.id, label: o.name + ` (${o.communal_territory.car_plate_symbol})` }))[0] ?? '' : ''}
              onChange={(val) => {
                handleChange({
                  birth_commune_id: (val === null || val === undefined) ? null : val.value
                })
              }}
              loadingMessage={() => "Caricando communi..."}
              clearValue
              isClearable
              noOptionsMessage={() => "Digita almeno tre lettere del comune!"}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderWidth: `2px !important`,
                  borderColor: `${error ? '#dc3545' : valid ? '#198754' : state.isSeleced ? defaultBorder : state.isFocused ? defaultBorder : defaultBorder}`,
                  padding: '0rem 0.75rem',
                  fontWeight: 'bold',
                  borderRadius: '0.375rem',
                  backgroundColor: '#FBFAFA',
                  '&:hover': defaultBorder,
                  boxShadow: 'none',
                })
              }}
            />
          </SelectContainer>
          <FormInputCheckbox
            label="Stato estero"
            name='born_abroad'
            checked={values.born_abroad}
            onChange={() => {
              handleChange({ born_abroad: !values.born_abroad });
            }}
          />
          {values.born_abroad === true && states !== undefined &&
            <AsyncSelect
              loadOptions={promiseOptionsStates}
              defaultOptions
              isSearchable
              name='country_of_birth_code'
              onChange={(val) => handleChange({
                birth_state_code: val !== null ? val.value : ''
              })}
              value={states.filter(opt => opt.id === values.birth_state_code).map(o => ({ value: o.id, label: o.name }))[0]}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderWidth: `2px !important`,
                  borderColor: `${error ? '#dc3545' : valid ? '#198754' : state.isSeleced ? defaultBorder : state.isFocused ? defaultBorder : defaultBorder}`,
                  padding: '0.2rem 0.75rem',
                  fontWeight: 'bold',
                  borderRadius: '0.375rem',
                  backgroundColor: '#FBFAFA',
                  '&:hover': defaultBorder,
                  boxShadow: 'none',
                  textAlign: 'center'
                })
              }}
              className='mt-3'
            />
          }
        </div>
      </div>
      <div className="text-center general-input-error">
        {error}
      </div>
    </div>
  )
}

export default FormReactSelectInputBirthPlace