import React from 'react'
import { Circle, ProgressContainer, StepOne, StepperContainer, BadgeProgress, ProgressContainerLast, ProgressLine, StepFour } from './ContractStepper.styles'
import { useState } from 'react';
import { useEffect } from 'react';
import currentStep from '../../../assets/redesign_icons/current-status.png'
import currentStepError from '../../../assets/redesign_icons/current-status-error.png'

function ContractStepper({ contractStatus }) {
  const [steppers, setSteppers] = useState([])

  const statuses = [
    {
      id: 4,
      name: "Attesa Pagamento",
      last: false,
      customId: 4,
      error: false
    },
    {
      id: 6,
      name: "In Attesa Emissione",
      last: false,
      customId: 5,
      error: false
    },
    {
      id: 7,
      name: "Emessa",
      last: true,
      customId: 6,
      error: false
    },
  ];

  useEffect(() => {
    setSteppers(
      [
        {
          id: 4,
          name: "Attesa Pagamento",
          last: false,
          customId: 4,
          error: false
        },
        {
          id: 6,
          name: "In Attesa Emissione",
          last: false,
          customId: 5,
          error: false
        },
        {
          id: 7,
          name: "Emessa",
          last: true,
          customId: 6,
          error: false
        },
      ]
    )

  }, [contractStatus])


  return (
    <StepperContainer>
      {steppers.map((status, index) => (
        status.last === false ?
          <StepOne key={index}>
            <ProgressContainer>
              {contractStatus === status.id ?
                <img src={status.error === true ? currentStepError : currentStep} alt="" width={45} /> :
                <Circle backgroundColor={contractStatus === status.id || (statuses.find(st => st.id === contractStatus).customId >= status.customId) ? '#0C85E9' : '#EDEDED'} />}
              <ProgressLine backgroundColor={status.error === true ? '#FFB4B4' : contractStatus === status.id || (statuses.find(st => st.id === contractStatus).customId >= status.customId) ? 'rgba(12, 133, 233, 0.4)' : '#EDEDED'} />
            </ProgressContainer>
            <h6 className='mb-2'>
              Step {index + 1}
            </h6>
            <h5 className='mb-2'>
              {status.name}
            </h5>
            <BadgeProgress
              backgroundColor={status.error === true ? '#FFB4B4' : contractStatus === status.id || (statuses.find(st => st.id === contractStatus).customId > status.customId) ? '#0C85E9' : '#EDEDED'} textColor={contractStatus === status.id || (statuses.find(st => st.id === contractStatus).customId > status.customId) ? '#ffffff' : '#8B8E97'}>
              {status.error === true ? 'Attenzione' : contractStatus === status.id ? 'In corso' : (statuses.find(st => st.id === contractStatus).customId > status.customId) ? 'Completa' : 'In Attesa'}
            </BadgeProgress>
          </StepOne>
          :
          <StepFour key={index}>
            <ProgressContainerLast>
              {contractStatus === status.id ? <img src={currentStep} alt="" width={45} /> : <Circle backgroundColor={'#EDEDED'} />}
            </ProgressContainerLast>
            <h6 className='mb-2'>
              Step {index + 1}
            </h6>
            <h5 className='mb-2'>
              {status.name}
            </h5>
            <BadgeProgress backgroundColor={contractStatus === status.id || (contractStatus !== 2 && statuses.find(st => st.id === contractStatus).customId > status.customId) ? '#0C85E9' : '#EDEDED'} textColor={contractStatus === status.id || (contractStatus !== 2 && statuses.find(st => st.id === contractStatus).customId > status.customId) ? '#ffffff' : '#8B8E97'}>
              {status.error === true ? 'Attenzione' : contractStatus === status.id ? 'In corso' : (contractStatus !== 2 && statuses.find(st => st.id === contractStatus).customId > status.customId) ? 'Completa' : 'In Attesa'}
            </BadgeProgress>
          </StepFour>
      ))}
    </StepperContainer>
  )
}

export default ContractStepper