import LoadingGif from "../../../assets/images/loading.gif";
import { PageKeepLoaderStyles } from "./PageLoading.styles";

export const PageKeepLoader = () => {
    return (
        <PageKeepLoaderStyles>
            <div className="d-flex content-loading">
                <div className="text-center">
                    <img className="loading-gif" src={LoadingGif} /><br />Attendere prego
                </div>
            </div>
        </PageKeepLoaderStyles>
    )
}