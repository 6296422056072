import styled from "styled-components";

export const ClientBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-basis: 300px;

  h6 {
    color: #0c85e9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 0;
  }

  h5 {
    color: var(--common-black, #1a1921);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 0;
  }
`;

export const VehicleBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-basis: 300px;

  h6 {
    color: #0c85e9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 0;
  }
`;

export const InfortuniPackBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-basis: 300px;

  h6 {
    color: #0c85e9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 0;
  }
`;

export const ProfessioneProductBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-basis: 300px;

  h6 {
    color: #0c85e9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 0;
  }
`;

export const TravelProductBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-basis: 300px;

  h6 {
    color: #0c85e9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 0;
  }
`;

export const QuoteInfoBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-basis: 300px;

  h6 {
    color: #0c85e9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin-bottom: 0;
  }

  h4 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
`;

export const ContactBox = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  width: 100%;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 200px;
  flex-basis: 300px;
  cursor: pointer;
  position: relative;

  img {
    width: 100px;
  }

  h5 {
    color: #0c85e9;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px; /* 107.692% */
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const MessageNumber = styled.div`
  width: 30px;
  height: 30px;
  background-color: red;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

export const BoxWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
`;

export const BoxMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
`;

export const MetaBoxCol = styled.div`
  margin-top: 30px;
  h6 {
    color: #1a1921;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }

  p {
    color: var(--common-black, #1a1921);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
`;
