import React, { useEffect, useState } from 'react'
import { PurchaseStyles } from './Purchase.styles'
import { useNavigate, useParams } from 'react-router-dom';
import PageLoading from '../../common/components/elements/PageLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux'
import { loadContractData, resetPurchaseData } from '../../features/purchaseContract/purchaseActions';
import InfoBox from '../../common/components/Purchase/InfoBox';
import ContractStepper from '../../common/components/Purchase/ContractStepper';
import WaitingForPaymentStatus from '../../common/components/Purchase/WaitingForPaymentStatus'
import CompletedQuoteScreen from './CompleteQuoteScreen';


const statuses = [
    {
        id: 4,
        name: "Attesa Pagamento",
    },
    {
        id: 6,
        name: "In Attesa Emissione",
    },
    {
        id: 7,
        name: "Polizza Emessa",
    },
];

function PurchaseContract() {
    const { contractId } = useParams()
    const { loading, contract, contractStatus } = useSelector(store => store.purchase.contract)
    const navigate = useNavigate()
    const collectQuoteData = async () => {
        loadContractData(contractId, navigate)
    };

    const navigateBack = () => {
        navigate(`/request/${contract.contract_request.id}/contracts`);
    }

    useEffect(() => {
        collectQuoteData();

        return () => {
            resetPurchaseData();
        }
    }, []);

    if (loading) {
        return <PageLoading />
    }

    return (
        <PurchaseStyles>
            {contractStatus !== 7 &&
                <>
                    <div className="container mt-0">
                        <div id="back-button" className="mb-3" onClick={() => navigateBack()}>
                            <FontAwesomeIcon icon={faArrowLeftLong} />
                        </div>
                        <ContractStepper contractStatus={contractStatus} />
                    </div>
                    <div className="container">
                        {contractStatus === 4 &&
                            <WaitingForPaymentStatus contract={contract} contractRequest={contract.contract_request} collectQuoteData={collectQuoteData} />
                        }

                        {contractStatus === 6 &&
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-8 offset-2">
                                        <div
                                            className="alert alert-success"
                                            role="alert"
                                        >
                                            <h4 className="alert-heading">
                                                In Attesa Emissione
                                            </h4>
                                            <p>
                                                Quando l'agente di backoffice carica la
                                                polizza, riceverai una notifica e il
                                                contratto potrà essere scaricato.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <InfoBox contract={contract} statuses={statuses} />
                    </div>
                </>
            }

            {contractStatus === 7 &&
                <>
                    <div id="back-button" className="mb-3" onClick={() => navigateBack()}>
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                    </div>
                    <CompletedQuoteScreen contract={contract} contractRequest={contract.contract_request} />
                </>
            }

        </PurchaseStyles>
    )
}

export default PurchaseContract