import * as React from "react";
import { CheckboxLabel } from "./FormInputCheckbox.styles";

export const FormInputCheckbox = ({
    label, registration, checked, onChange
}) => {
    if (registration === undefined) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <input type="checkbox" className="checkbox" checked={checked} onChange={onChange} />
                <CheckboxLabel>{label}</CheckboxLabel>
            </div>
        );
    }

    return (
        <div className="d-flex align-items-center justify-content-center">
            <input type="checkbox" className="checkbox" {...registration} />
            <CheckboxLabel>{label}</CheckboxLabel>
        </div>
    );
}
