import React from 'react'
import { BaseTitle, DefaultInfo, GuaranteesActionsCol, GuaranteesDefaultStateRequestStyles, GuaranteesInfoCol } from './GuaranteesDefaultStateRequest.styles'
import warningIcon from '../../assets/redesign_icons/warning-icon.svg'
import arrowDown from '../../assets/redesign_icons/arrow-down.svg'

export const GuaranteesDefaultStateRequest = () => {
  return (
    <GuaranteesDefaultStateRequestStyles>
      <GuaranteesInfoCol>
        <BaseTitle>
          <h4>Aggiungi <b>Markup</b></h4>
          <p>
            Se vuoi aggiungere qualche markup extra al tuo prodotto puoi aggiungerla sotto
          </p>
        </BaseTitle>
        <DefaultInfo>
          <img src={warningIcon} alt="" />
          <p>
            Se la quotazione e in processo di aquisto markup non puo essere cambiato!
          </p>
        </DefaultInfo>
      </GuaranteesInfoCol>
      <GuaranteesActionsCol>
        <img src={arrowDown} alt="" />
      </GuaranteesActionsCol>
    </GuaranteesDefaultStateRequestStyles>
  )
}