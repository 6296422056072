import React, { useEffect, useState } from 'react'
import { FormGroup, Label } from './styles/common'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'

function FormReactSelectBrand({ label, options, error, valid, onChange, values }) {
  const [prepOptions, setPrepOptions] = useState([])
  const [inputText, setInputText] = useState('');

  let defaultBorder = '#CBCBCD';

  const filterBrand = (inputValue) => {
    if (inputValue.length >= 1) {
      return prepOptions.filter((i) =>
        i.label.toLowerCase().startsWith(inputValue.toLowerCase())
      );
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterBrand(inputValue));
      }, 500);
    });

  const handleChange = (value) => {
    if (value.vehicle_brand_code !== undefined || value.vehicle_brand_code !== '') {
      let brand = options.find(opt => opt.id == value.vehicle_brand_code)
      if (brand !== undefined) {
        onChange({
          vehicle_brand_code: brand.id.toString(),
        })
      }
    }

    if (value.vehicle_brand_code === null || value.vehicle_brand_code === '' || value.vehicle_brand_code === undefined) {
      onChange({
        vehicle_brand_code: '',
      })
    }
  }

  const handleInputChange = (inputValue) => {
    setInputText(inputValue);
  };

  const handleMenuClose = () => {
    if (!values.vehicle_brand_code && inputText) {
      const startingWithOptions = prepOptions.filter(option =>
        option.label.toLowerCase().startsWith(inputText.toLowerCase())
      );
      const firstStartingOption = startingWithOptions[0];
      const findCarPlateSymbol = options.find(opt => opt.id == firstStartingOption.value)
      if (firstStartingOption && findCarPlateSymbol) {
        onChange({
          vehicle_brand_code: firstStartingOption.value.toString(),
        });
      }
    }
  };

  useEffect(() => {
    setPrepOptions(options.map((i) => ({ value: i.id, label: i.name })))
  }, [options])


  return (
    <FormGroup>
      <Label>{label}</Label>
      <Select
        onMenuClose={handleMenuClose}
        onInputChange={handleInputChange}
        options={prepOptions}
        isSearchable
        placeholder={''}
        value={options.filter(opt => opt.id == values.vehicle_brand_code).map(o => ({ value: o.id, label: o.name }))[0]}
        onChange={(val) => {
          handleChange({
            vehicle_brand_code: val === null || val === undefined ? '' : val.value
          })
        }}
        loadingMessage={() => "Caricando marce..."}
        clearValue
        isClearable
        noOptionsMessage={() => "Marce non trovati!"}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderWidth: `2px !important`,
            borderColor: `${error ? '#dc3545' : valid ? '#198754' : state.isSeleced ? defaultBorder : state.isFocused ? defaultBorder : defaultBorder}`,
            padding: '0rem 0.75rem',
            fontWeight: 'bold',
            borderRadius: '0.375rem',
            backgroundColor: '#FBFAFA',
            '&:hover': defaultBorder,
            boxShadow: 'none',
            textAlign: 'center',
          }),
          singleValue: (provided) => ({
            ...provided,
            marginLeft: '15%'
          })
        }}
      />
      <div className={`invalid-feedback ${error ? 'd-block' : ''}`}>{error}</div>
    </FormGroup>
  )
}

export default FormReactSelectBrand