import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./user/userSlice";
import { utilitiesSlice } from "./contractRequests/contractRequestsSlice";
import { purchaseSlice } from "./purchaseContract/purchaseSlice";
import { contractsSlice } from "./contracts/contractsSlice";

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    utilities: utilitiesSlice.reducer,
    purchase: purchaseSlice.reducer,
    contracts: contractsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
