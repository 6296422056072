import styled from 'styled-components';

export const DashboardThemeStyles = styled.div`
    .page-content{
        width: calc(100% - 300px);
        margin-left: auto;
    }
    .page-content-items{
        padding: 30px;
    }
    @media screen and (max-width: 991px) {
        .page-content {
            width: 100%;
            margin-left: auto;
        }

      .page-content-items {
        padding: 10px;
      }
    }
`;
