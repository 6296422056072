import { Paragraph } from "./FormInputText.styles";
import { FormGroup, Label } from "./styles/common";

export const FormInputText = ({
    label, paragraph, type, placeholder, min, max, registration, valid, error, value, onChange, inputId
}) => {
    if (registration === undefined) {
        const handleChange = (e) => {
            onChange(e.target.value.trim());
        }

        return (
            <FormGroup>
                {label !== undefined &&
                    <Label>{label}</Label>
                }
                {paragraph !== undefined &&
                    <Paragraph>{paragraph}</Paragraph>
                }
                <input placeholder={placeholder} id={inputId} className={`form-control text-center ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} type={type} min={min} max={max} value={value} onChange={handleChange} />
                <div className="invalid-feedback">
                    {error}
                </div>
            </FormGroup>
        );
    }

    return (
        <FormGroup>
            {label !== undefined &&
                <Label>{label}</Label>
            }
            {paragraph !== undefined &&
                <Paragraph>{paragraph}</Paragraph>
            }
            <input id={inputId} placeholder={placeholder} className={`form-control text-center ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} type={type} min={min} max={max} {...registration} />
            <div className="invalid-feedback">
                {error}
            </div>
        </FormGroup>
    );
}

