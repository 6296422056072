import React from "react";
import GreeniaLogo from "../../assets/images/greenia-logo.png";
import ListaProdotti from "../../assets/images/lista-prodotti.png";
import Portagolgio from "../../assets/images/portagolgio.png";

import User from "../../assets/images/user.png";
import { SidebarStyles } from "./Sidebar.styles";
import { Link } from "react-router-dom";
import appStorage from "../helpers/appStorage";
import BellIcon from "../../assets/images/icons/BellIcon";

export default function Sidebar({ showSidebar, setShowSidebar }) {
  const closeSidebar = () => {
    setShowSidebar(false);
  };

  return (
    <SidebarStyles>
      <div className="col-lg-3" id="sidear-wrapper">
        <nav
          id="sidebarMenu"
          className={`sidebar ${!showSidebar ? "d-none" : ""} d-lg-block`}
        >
          {/* <Link
            onClick={closeSidebar}
            className="navbar-brand mt-2 mt-lg-0"
            to="/"
          >
            <div className="d-flex justify-content-center header-logo">
              <img src={GreeniaLogo} alt="App Logo" />
            </div>
          </Link> */}
          <div onClick={closeSidebar} className="hide d-lg-none">
            &lsaquo;
          </div>
          <div className="mt-md-4 mt-2">
            <ul>
              <li>
                <Link
                  onClick={closeSidebar}
                  to="/notifications"
                  className="list-group-item list-group-item-action py-2 ripple "
                >
                  <span
                    className={
                      "text-danger"
                    }
                  >
                    <BellIcon width="24px" height="20px" />
                  </span>

                  <span className="item">
                    Notifiche
                  </span>
                </Link>
              </li>
              <li className="dropdown">
                <Link
                  onClick={closeSidebar}
                  to="/quote/form"
                  className="list-group-item list-group-item-action py-2 ripple"
                >
                  <img
                    className="item-img"
                    src={ListaProdotti}
                    alt="Products List"
                  />
                  <span className="item">Nuovo preventivo</span>
                </Link>
              </li>
              <li className="dropdown">
                <Link
                  onClick={closeSidebar}
                  to="/not-saved-contract-request"
                  className="list-group-item list-group-item-action py-2 ripple"
                >
                  <img
                    className="item-img"
                    src={Portagolgio}
                    alt="Not Saved Contract Requests"
                  />
                  <span className="item">Contratti Non Salvati</span>
                </Link>
              </li>
              <li className="dropdown">
                <Link
                  onClick={closeSidebar}
                  to="/contratti-aquistati"
                  className="list-group-item list-group-item-action py-2 ripple"
                >
                  <img
                    className="item-img"
                    src={Portagolgio}
                    alt="Contratti Acquistati"
                  />
                  <span className="item">Contratti Acquistati</span>
                </Link>
              </li>
              <li className="dropdown">
                <Link
                  onClick={closeSidebar}
                  to="/profile"
                  className="list-group-item list-group-item-action py-2 ripple"
                >
                  <img className="item-img" src={User} alt="" />
                  <span className="item">Profilo</span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </SidebarStyles>
  );
}
