import React, { useEffect, useState } from 'react'
import pdfIMG from "../../assets/images/purchase/pdf.png";
import downloadIMG from "../../assets/images/purchase/download.png";
import { apiDownloadDocument } from '../../services/axios-client/axeCommons';

const CompletedQuoteScreen = ({ contract, contractRequest }) => {
    return (
        <div className="row">
            <div className="col-xl-6">
                <div className="card border mb-2">
                    <div className="card-header bg-secondary text-white">
                        <h2 className="h3 mb-0 card-title">
                            Anagrafica
                        </h2>
                    </div>
                    <div className="card-body">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <b>Nome e Cognome:</b>  {contractRequest.request_client.name} {contractRequest.request_client.surname}
                            </li>
                            <li className="list-group-item">
                                <b>Email:</b>  {contractRequest.request_client.email}
                            </li>
                            <li className="list-group-item">
                                <b>Telefono:</b>  {contractRequest.request_client.phone}
                            </li>
                            <li className="list-group-item">
                                <b>Indirizo:</b>  {contractRequest.request_client.address}, {contractRequest.request_client.house_number}, {contractRequest.request_client.postal_code}
                            </li>
                            <li className="list-group-item">
                                <b>Residenza:</b>  {contractRequest.request_client.residence.name}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card border">
                    <div className="card-header bg-secondary text-white">
                        <h2 className="h3 mb-0 card-title">
                            Dati Prodotto
                        </h2>
                    </div>
                    <div className="card-body">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <b>Nome di prodotto:</b>  {contract.product.name}
                            </li>
                            <li className="list-group-item">
                                <b>Prezzo base:</b>  €{parseFloat(contract.initial_amount)}
                            </li>
                            <li className="list-group-item">
                                <b>Markup Aggiunto:</b>  {parseFloat(contract.markup)}&euro;
                            </li>
                            <li className="list-group-item">
                                <b>Targa Veicolo:</b>  {contractRequest.request_vehicle.vehicle_plate}
                            </li>
                            <li className="list-group-item">
                                <b>Marca Veicolo:</b>  {contractRequest.request_vehicle.brand.name}
                            </li>
                            <li className="list-group-item">
                                <b>Modelo Veicolo:</b>{contractRequest.request_vehicle.model.name}
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="card border mb-2">
                    <div className="card-header bg-secondary text-white">
                        <h2 className="h3 mb-0 card-title">
                            Dati Polizza
                        </h2>
                    </div>
                    <div className="card-body">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <b>Compagnia: </b>  Roadside Assistance
                            </li>
                            <li className="list-group-item">
                                <b>Stato:</b>  {contract.status === 7 && 'Polizza Emmesa'}
                            </li>
                            <li className="list-group-item">
                                <b>Polizza Pagata:</b>  SI
                            </li>
                            <li className="list-group-item">
                                <b>Totale:</b>  {contract.amount.toLocaleString()} &euro;
                            </li>
                        </ul>
                    </div>

                </div>
                <div className="card border">
                    <div className="card-header bg-secondary text-white">
                        <h2 className="h3 mb-0 card-title">
                            Documenti Polizza
                        </h2>
                    </div>
                    <div className="card-body">
                        <ol className="list-group list-group-numbered">
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto w-100">
                                    <div className="fw-bold mb-2">Tessera</div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <img src={pdfIMG} className="img-fluid" width="25" alt="PDF" />
                                        <div className="mx-2 me-auto">
                                            <h5 className="h6 mb-0">Fattura</h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <a
                                                target='_blank'
                                                className="doc-btn border-0 bg-transparent"
                                                onClick={() => apiDownloadDocument(contract.id)}
                                            >
                                                <img src={downloadIMG} width="25" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompletedQuoteScreen
