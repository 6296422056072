import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PageLoading from '../../common/components/elements/PageLoading'
import { getNotSavedContractRequests } from '../../features/contractRequests/contractRequestsActions'
import { NotSavedContractStyles } from './NotSavedContractRequests.styles'
import { Link } from 'react-router-dom'
import { generalAlertError } from '../../common/utils/alerts'

function NotSavedContractRequests() {
    const { loading, contractRequestsNotSaved } = useSelector(store => store.utilities.not_saved_requests)
    const [search, setSearch] = useState({
        search: '',
        plate: '',
        date_from: '',
        date_to: ''
    })

    const searchHandler = (e) => {
        e.preventDefault();

        if (search.date_from !== '' && search.date_to === '') {
            return generalAlertError('Devi selezionare anche data al')
        }

        if (search.date_from === '' && search.date_to !== '') {
            return generalAlertError('Devi selezionare anche data da')
        }
        getNotSavedContractRequests(search);
    }

    const handleChange = ({ target: { value, name } }) => {
        setSearch({ ...search, [name]: value })
    }

    useEffect(() => {
        getNotSavedContractRequests()
    }, [])

    if (loading) {
        return <PageLoading />
    }
    return (

        <NotSavedContractStyles>
            <h5 className='mb-4'>Richieste Non Salvate</h5>
            <div className="d-flex flex-md-row flex-column-reverse">
                <div className="col-xl-8 col-md-7 col-lg-8">
                    <div className="mt-3">
                        {loading ?
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <div className="spinner-border" role="status"></div>
                                <span>Attendere prego...</span>
                            </div>
                            :
                            <ul className="list-group">
                                {contractRequestsNotSaved.length > 0 ?
                                    contractRequestsNotSaved.map((contractRequest, index) => (
                                        <li key={index} className="list-group-item d-flex flex-xl-row flex-column justify-content-between align-items-center">
                                            <div className="ms-2 me-auto mb-2">
                                                {contractRequest.request_client && <div className="fw-bold">
                                                    Cliente: {contractRequest.request_client.gender === 'G' ? contractRequest.request_client.business_name : contractRequest.request_client.name + ' ' + contractRequest.request_client.surname}
                                                </div>}
                                                {contractRequest.request_vehicle &&
                                                    <>
                                                        Targa: {contractRequest.request_vehicle.vehicle_plate}
                                                    </>
                                                }
                                                <br />
                                                <small>
                                                    <strong>Creato: {new Date(contractRequest.created_at).toLocaleDateString('it-IT', { 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' })}</strong>
                                                </small>
                                            </div>

                                            <Link to={`/request/${contractRequest.id}/contracts`}>
                                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                                    Vai a preventivi
                                                </span>
                                            </Link>
                                        </li>
                                    ))
                                    :
                                    <li className="list-group-item d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">
                                                Nessuna richiesta trovata!
                                            </div>
                                        </div>
                                    </li>}
                            </ul>
                        }
                    </div>

                </div>
                <div className="col-lg-3 col-md-5 col-xl-3 p-3 quotation-filters">
                    <form onSubmit={searchHandler} className='d-flex align-items-end justify-content-center flex-column'>
                        <input type="text" name='search' className='search-input form-control mb-2' value={search.search} onChange={handleChange} placeholder='Ricerca quotazioni salvate...' />
                        <input type="text" name='plate' className='search-plate form-control mb-2' value={search.plate} onChange={handleChange} placeholder='Ricerca targa...' />
                        <div className='w-100'>
                            <label htmlFor="date_from">Data creazione da</label>
                            <input type="date" name='date_from' onChange={handleChange} value={search.date_from} className='date-input form-control mb-2' placeholder='Data creazione da' />
                        </div>
                        <div className='w-100'>
                            <label htmlFor="date_to">Data creazione al</label>
                            <input type="date" name='date_to' className='date-input form-control mb-0' onChange={handleChange} value={search.date_to} min={search.date_from} placeholder='Data creazione al' />
                        </div>
                        <button type="submit" className="search-btn mt-2">
                            Cerca
                        </button>
                    </form>
                </div>
            </div>
        </NotSavedContractStyles>
    )
}

export default NotSavedContractRequests