import React, { useEffect, useState } from 'react'
import { FormGroup, Label } from './styles/common'
import AsyncSelect from 'react-select/async'

function FormReactSelectResidenceInput({ label, children, options, onChange, values, error, valid }) {
  const [prepOptions, setPrepOptions] = useState([])
  const [inputText, setInputText] = useState('');

  let defaultBorder = '#CBCBCD';

  const filterMunicipalities = (inputValue) => {
    if (inputValue.length >= 3) {
      return prepOptions.filter((i) =>
        i.label.toLowerCase().startsWith(inputValue.toLowerCase())
      );
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterMunicipalities(inputValue));
      }, 500);
    });

  const handleChange = (value) => {
    if (value.residence_commune_id !== undefined || value.residence_commune_id !== '') {
      let commune = options.find(opt => opt.id === value.residence_commune_id)
      if (commune !== undefined) {
        onChange({
          residence_commune_id: commune.id,
          postal_code: commune.postal_code,
        })
      }
    }

    if (value.residence_commune_id === null || value.residence_commune_id === '' || value.residence_commune_id === undefined) {
      onChange({
        residence_commune_id: null,
        postal_code: '',
      })
    }
  }

  const handleInputChange = (inputValue) => {
    setInputText(inputValue);
  };

  const handleMenuClose = () => {
    if (!values.residence_commune_id && inputText) {
      const startingWithOptions = prepOptions.filter(option =>
        option.label.toLowerCase().startsWith(inputText.toLowerCase())
      );
      const firstStartingOption = startingWithOptions[0];
      const findCarPlateSymbol = options.find(opt => opt.id === firstStartingOption.value)
      if (firstStartingOption && findCarPlateSymbol) {
        onChange({
          residence_commune_id: firstStartingOption.value,
          postal_code: findCarPlateSymbol.postal_code
        });
      }
    }
  };

  useEffect(() => {
    setPrepOptions(options.map((i) => ({ value: i.id, label: i.name + ` (${i.communal_territory.car_plate_symbol})` })))
  }, [options])

  return (
    <div className={`d-flex align-items-start justify-content-between flex-wrap`} style={{ columnGap: 20 }}>
      <FormGroup className='position-relative' style={{ flex: 2 }}>
        <Label>{label}</Label>
        <AsyncSelect
          onMenuClose={handleMenuClose}
          onInputChange={handleInputChange}
          loadOptions={promiseOptions}
          defaultOptions
          isSearchable
          placeholder={''}
          value={options.filter(opt => opt.id === values.residence_commune_id).map(o => ({ value: o.id, label: o.name + ` (${o.communal_territory.car_plate_symbol})` }))[0] ?? ''}
          onChange={(val) => {
            handleChange({
              residence_commune_id: (val === null || val === undefined) ? null : val.value
            })
          }}
          loadingMessage={() => "Caricando communi..."}
          clearValue
          isClearable
          noOptionsMessage={() => "Digita almeno tre lettere del comune!"}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderWidth: `2px !important`,
              borderColor: `${error ? '#dc3545' : valid ? '#198754' : state.isSeleced ? defaultBorder : state.isFocused ? defaultBorder : defaultBorder}`,
              padding: '0.05rem 0.75rem',
              fontWeight: 'bold',
              borderRadius: '0.375rem',
              backgroundColor: '#FBFAFA',
              '&:hover': defaultBorder,
              boxShadow: 'none',
              textAlign: 'center'
            })
          }}
        />
        <div className={`invalid-feedback ${error ? 'd-block' : ''}`}>{error}</div>
      </FormGroup>
      {children !== undefined &&
        <div style={{ flex: 1, flexBasis: 50 }}>
          {children}
        </div>
      }
    </div>
  )
}

export default FormReactSelectResidenceInput