import React from 'react'
import { BoxMeta, BoxWrapper, ClientBox, ContactBox, MessageNumber, MetaBoxCol, QuoteInfoBox, VehicleBox } from './CommonPurchase.styles'

function InfoBox({ contract, statuses }) {
  return (
    <>
      <BoxWrapper>
        <ClientBox>
          <h6>Cliente:</h6>
          <h5>
            {contract.contract_request.request_client.gender !== 'G' ? contract.contract_request.request_client.name + ' ' + contract.contract_request.request_client.surname : contract.contract_request.request_client.business_name}
          </h5>
          <BoxMeta>
            <MetaBoxCol>
              <h6>Decorrenza</h6>
              <p>
                {new Date(
                  contract.contract_request.start_date
                ).toLocaleDateString("it-IT")}
              </p>
            </MetaBoxCol>
            <MetaBoxCol>
              <h6>Scadenza</h6>
              <p>
                {new Date(
                  contract.contract_request.end_date
                ).toLocaleDateString("it-IT")}
              </p>
            </MetaBoxCol>
          </BoxMeta>
        </ClientBox>
        <VehicleBox>
          <h6>{contract.contract_request.request_vehicle.brand.name} {contract.contract_request.request_vehicle.model.name}</h6>
          <BoxMeta>
            <MetaBoxCol>
              <h6>Targa</h6>
              <p>
                {contract.contract_request.request_vehicle.vehicle_plate}
              </p>
            </MetaBoxCol>
          </BoxMeta>
          <BoxMeta>
            <MetaBoxCol className='mt-0'>
              <h6>Durata</h6>
              <p>
                12 Mesi
              </p>
            </MetaBoxCol>
            <MetaBoxCol className='mt-0'>
              <h6>Nome Prodotto</h6>
              <p>
                {contract.product.name}
              </p>
            </MetaBoxCol>
          </BoxMeta>
        </VehicleBox>
        <QuoteInfoBox>
          <h6>Total</h6>
          <h4>
            &euro; {contract.amount.toLocaleString()} <sub style={{ 'fontSize': '14px' }}>iva inclusa</sub>
          </h4>
          <BoxMeta>
            <MetaBoxCol>
              <h6>Status</h6>
              <p>
                {statuses.find(st => st.id === contract.status).name}
              </p>
            </MetaBoxCol>
          </BoxMeta>
        </QuoteInfoBox>
      </BoxWrapper>
    </>
  )
}

export default InfoBox